import React, { useState, useEffect, useReducer } from 'react';

import { Box, TextField, Tooltip } from "@mui/material";

import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';

import Loading from '../../Loading';
import useNotification from '../../Notification'

import api from '../../../api';

import IntegerOnly from '../../../utils/IntegerOnly';

import './styles.css'
import defaultReducer from '../../../utils/defaultReducer';
import { getSession, saveSession } from '../../../auth';

const Messages = ({ enterpriseId }) => {

    const [state, setState] = useReducer(defaultReducer, {
        default_force_hangup_message: '',
        default_auto_dispatch_message: '',
    })

    const [botTimeoutMessage, setBotTimeoutMessage] = useState({
        var_val: 'Chat encerrado por inatividade.'
    });
    const [chatTimeoutMessage, setChatTimeoutMessage] = useState({
        var_val: 'Chat encerrado por inatividade.'
    });
    const [surveyTimeoutMessage, setSurveyTimeoutMessage] = useState({
        var_val: 'Você não respondeu à pesquisa de satisfação, estamos finalizando seu atendimento.'
    });

    const [queueTimeoutMessage, setQueueTimeoutMessage] = useState('Chat encerrado pelo sistema.')

    const [botTimeoutTime, setBotTimeoutTime] = useState(0)
    const [chatTimeoutTime, setChatTimeoutTime] = useState(0)
    const [surveyTimeoutTime, setSurveyTimeoutTime] = useState(0)
    const [queueTimeoutTime, setQueueTimeoutTime] = useState(0)

    const [loading, setLoading] = useState(true);
    const [patchLoading, setPatchLoading] = useState(false);

    const getMessagesAndTimes = async () => {
        setLoading(true)
        const url = `api/enterprises/${enterpriseId}/config/`;
        const res = await api.get(url);

        if (res.status == 200) {
            res.data.forEach((config) => {
                switch (config.var_name) {
                    case 'bot_timeout':
                        setBotTimeoutTime(config.var_val)
                        break
                    case 'bot_timeout_message':
                        setBotTimeoutMessage(config.var_val);
                        break
                    case 'chat_timeout':
                        setChatTimeoutTime(config.var_val)
                        break
                    case 'chat_timeout_message':
                        setChatTimeoutMessage(config.var_val);
                        break
                    case 'survey_timeout_message':
                        setSurveyTimeoutMessage(config.var_val);
                        break
                    case 'survey_timeout':
                        setSurveyTimeoutTime(config.var_val)
                        break;
                    case 'queue_timeout':
                        setQueueTimeoutTime(config.var_val)
                        break
                    case 'queue_timeout_message':
                        setQueueTimeoutMessage(config.var_val)
                        break
                    case 'default_force_hangup_message':
                        setState({ default_force_hangup_message: config.var_val })
                        break;
                    case 'default_auto_dispatch_message':
                        setState({ default_auto_dispatch_message: config.var_val })
                        break;
                    default:
                        break
                }
            });
        }
        else {
            useNotification(
                'Ops!',
                'Ocorreu um erro ao carregar as mensagens.',
                'danger'
            );
        }

        setLoading(false)
    }

    const patchALL = async () => {
        setPatchLoading(true);
        const res = await Promise.all([patchConfigs()])
        setPatchLoading(false);

        const result = res.reduce((x, y) => x == 200 && y == 200)

        if (!result) {
            useNotification(
                'Ops!',
                'Ocorreu um erro ao salvar as mensagens.',
                'danger'
            );
        } else {
            useNotification(
                'Sucesso!',
                'As mensagens foram salvas com sucesso.',
                'success'
            );
        }
    }

    const checkNullTime = async (data) => {
        if (data.bot_timeout === "" || data.chat_timeout === "" || data.survey_timeout === "" || data.queue_timeout === "") {
            useNotification("Ops!", "O tempo de timeout não pode ser vazio.", "warning")
            return false
        }
        return true
    }

    const patchConfigs = async () => {
        const data = {
            'bot_timeout': botTimeoutTime,
            'bot_timeout_message': botTimeoutMessage,
            'chat_timeout': chatTimeoutTime,
            'chat_timeout_message': chatTimeoutMessage,
            'survey_timeout_message': surveyTimeoutMessage,
            'survey_timeout': surveyTimeoutTime,
            'queue_timeout': queueTimeoutTime,
            'queue_timeout_message': queueTimeoutMessage,
            'default_force_hangup_message': state.default_force_hangup_message,
            'default_auto_dispatch_message': state.default_auto_dispatch_message,
        }

        if (!await checkNullTime(data))
            return false

        const base_url = `api/enterprises/${enterpriseId}/config/updatedata/`;

        const res = await api.patch(base_url, data);

        const session = getSession()
        session.profile.enterprise.settings = { ...session.profile.enterprise.settings, ...data }
        saveSession(session)
        return res.status
    }

    useEffect(() => {
        getMessagesAndTimes();
    }, [])

    return (
        <Box className="input-settings" sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
            <Loading loading={loading} />
            <Box>
                <Box className="category-title push-bottom">
                    Mensagem padrão para finalizar chats em 'bot' ou 'fila'
                </Box>
                <Box className="messages-flexbox push-bottom">
                    <Tooltip title="Mensagem padrão para finalização de chats em 'bot' ou 'fila'">
                        <TextField
                            fullWidth
                            multiline
                            maxRows={4}
                            size="small"
                            label={`Mensagem ${state.default_force_hangup_message.length}/5000`}
                            type="text"
                            variant="outlined"
                            value={state.default_force_hangup_message}
                            onChange={(event) => setState({ default_force_hangup_message: event.target.value })}
                            inputProps={{
                                maxLength: 5000
                            }}
                        />
                    </Tooltip>
                </Box>
                <Box className="category-title push-bottom">
                    Timeout do chatbot
                </Box>
                <Box className="messages-flexbox">
                    <Tooltip title='Número entre 10 e 86400'>
                        <TextField
                            sx={{ flex: 0.15 }}
                            size="small"
                            multiline
                            label="Tempo (segundos)"
                            type="text"
                            variant="outlined"
                            value={botTimeoutTime}
                            onChange={(event) => setBotTimeoutTime(IntegerOnly(event.target.value, 1, 86400))}
                            onBlur={(event) => setBotTimeoutTime(IntegerOnly(event.target.value, 10, 86400))}
                        />
                    </Tooltip>
                    <TextField
                        sx={{ flex: 0.85 }}
                        size="small"
                        multiline
                        label="Mensagem"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={botTimeoutMessage}
                        onChange={(event) => setBotTimeoutMessage(event.target.value)}
                    />
                </Box>
            </Box>
            <Box>
                <Box className="category-title push-bottom">
                    Timeout da fila
                </Box>
                <Box className="messages-flexbox">
                    <Tooltip title='Número entre 10 e 604800'>
                        <TextField
                            sx={{ flex: 0.15 }}
                            size="small"
                            multiline
                            label="Tempo (segundos)"
                            type="text"
                            variant="outlined"
                            value={queueTimeoutTime}
                            onChange={(event) => setQueueTimeoutTime(IntegerOnly(event.target.value, 1, 604800))}
                            onBlur={(event) => setQueueTimeoutTime(IntegerOnly(event.target.value, 10, 604800))}
                        />
                    </Tooltip>
                    <Tooltip title='As mensagens de timeout em fila só serão enviadas se o timeout for menor que 24h (86400s)'>
                        <TextField
                            sx={{ flex: 0.85 }}
                            size="small"
                            multiline
                            label="Mensagem"
                            fullWidth
                            type="text"
                            disabled={queueTimeoutTime > 86400}
                            variant="outlined"
                            value={queueTimeoutMessage}
                            onChange={(event) => setQueueTimeoutMessage(event.target.value)}
                            inputProps={{
                                maxlength: 255
                            }}
                        />
                    </Tooltip>
                </Box>
            </Box>
            <Box>
                <Box className="category-title push-bottom">
                    Timeout do chat
                </Box>
                <Box className="messages-flexbox">
                    <Tooltip title='Número entre 10 e 86400'>
                        <TextField
                            sx={{ flex: 0.15 }}
                            size="small"
                            multiline
                            label="Tempo (segundos)"
                            type="text"
                            variant="outlined"
                            value={chatTimeoutTime}
                            onChange={(event) => setChatTimeoutTime(IntegerOnly(event.target.value, 1, 86400))}
                            onBlur={(event) => setChatTimeoutTime(IntegerOnly(event.target.value, 10, 86400))}
                        />
                    </Tooltip>
                    <TextField
                        sx={{ flex: 0.85 }}
                        size="small"
                        multiline
                        label="Mensagem"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={chatTimeoutMessage}
                        onChange={(event) => setChatTimeoutMessage(event.target.value)}
                    />
                </Box>
            </Box>
            <Box>
                <Box className="category-title push-bottom">
                    Timeout da pesquisa de satisfação
                </Box>
                <Box className="messages-flexbox">
                    <Tooltip title='Número entre 10 e 86400'>
                        <TextField
                            sx={{ flex: 0.15 }}
                            size="small"
                            multiline
                            label="Tempo (segundos)"
                            type="text"
                            variant="outlined"
                            value={surveyTimeoutTime}
                            onChange={(event) => setSurveyTimeoutTime(IntegerOnly(event.target.value, 1, 86400))}
                            onBlur={(event) => setSurveyTimeoutTime(IntegerOnly(event.target.value, 10, 86400))}
                        />
                    </Tooltip>
                    <TextField
                        sx={{ flex: 0.85 }}
                        size="small"
                        multiline
                        label="Mensagem"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={surveyTimeoutMessage}
                        onChange={(event) => setSurveyTimeoutMessage(event.target.value)}
                    />

                </Box>
            </Box>
            <Box>
                <Box className="category-title push-bottom">
                    Mensagem padrão para distribuição automática de chat.
                </Box>
                <Box className="messages-flexbox push-bottom">
                    <Tooltip title="Mensagem padrão para distribuição automática de chat.">
                        <TextField
                            fullWidth
                            multiline
                            maxRows={4}
                            size="small"
                            label={`Mensagem ${state.default_auto_dispatch_message.length}/5000`}
                            type="text"
                            variant="outlined"
                            value={state.default_auto_dispatch_message}
                            onChange={(event) => setState({ default_auto_dispatch_message: event.target.value })}
                            inputProps={{
                                maxLength: 5000
                            }}
                        />
                    </Tooltip>
                </Box>
            </Box>
            <LoadingButton
                style={{ width: 'fit-content' }}
                onClick={patchALL}
                loading={patchLoading}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
            >
                Salvar
            </LoadingButton>
        </Box>
    )
}

export default Messages
