import React from 'react'

import { Box } from '@mui/material'

import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PauseIcon from '@mui/icons-material/Pause';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstantChatIcon from '@mui/icons-material/Language';
import MicrosoftIcon from '@mui/icons-material/Groups';
import EmailIcon from '@mui/icons-material/Email';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import ApiIcon from '@mui/icons-material/DataObject';
import ApartmentIcon from '@mui/icons-material/Apartment';

import useNotification from "../../../Notification";

import api from '../../../../api';

import dayjs from 'dayjs'

const convertAudio = async (exportUrl, accountcode) => {

    const data = {
        file: exportUrl
    }

    const res = await api.post(`/api/convertaudio/`, data)

    if (res.status == 200)
        return res.data
    console.error('Failed to convert data.')
    return ''
}

const handleAudioPlay = async (row, enterprise, smallLoading, playingAudio, setSmallLoading, setPlayingAudio) => {

    if (smallLoading)
        return

    if (playingAudio.audio != null) {
        playingAudio.audio.pause()
        playingAudio.audio.currentTime = 0;
    }

    setSmallLoading(true)
    setPlayingAudio({ id: row.id, audio: null })

    const accountcode = enterprise.accountcode
    const uniqueid = row.uniqueid;
    const type = row.type == 'Entrante' ? 'inbound' : 'outbound';
    const date = row.date.replaceAll('-', '');
    const destination = row.destination;
    const fileName = `${uniqueid}.WAV`;
    const leg = 0;

    let directory = `${accountcode}_${destination}`;
    if (type === 'inbound')
        // Directory name is different for inbound calls
        directory = directory.split('_').pop();

    //const exportUrl = `/export/${accountcode}/${type}/${date}/${directory}/${fileName}`;

    let exportUrl = ''

    let source = 'messages'

    try {
        if (row[source])
            exportUrl = atob(row[source])
    } catch (e) {
        console.error(e)
        exportUrl = ''
    }

    let audio = new Audio(exportUrl);
    audio.onended = (() => { setPlayingAudio({ id: null, audio: null }) })
    try {
        await audio.play();
        setPlayingAudio({ id: row.id, audio: audio })
    } catch (err) {
        useNotification(
            'Aguarde!',
            'O áudio está sendo preparado.',
            'info'
        )
        try {
            const res = await convertAudio(exportUrl, accountcode)
            audio = new Audio(res);
            audio.onended = (() => { setPlayingAudio({ id: null, audio: null }) })
            await audio.play()
            setPlayingAudio({ id: row.id, audio: audio })
        }
        catch (err) {
            console.error(err)
            setPlayingAudio({ id: null, audio: null })
            useNotification(
                'Ops...',
                'O arquivo de áudio não existe.',
                'danger'
            )
        }

    }

    setSmallLoading(false)

}

const getChatIcon = (platform) => {
    switch (platform) {
        case 'WHATSAPP_ATIVO':
            return <ApartmentIcon className="fileIcon" sx={{ color: '#21446C' }} />
        case 'WHATSAPP':
            return <WhatsAppIcon className="fileIcon" sx={{ color: '#34af23' }} />
            break
        case 'TELEGRAM':
            return <TelegramIcon className="fileIcon" sx={{ color: '#3390ec' }} />
            break
        case 'INSTAGRAM':
            return <InstagramIcon className="fileIcon" sx={{ color: '#fd6b10' }} />
            break
        case 'MESSENGER':
            return <FacebookIcon className="fileIcon" sx={{ color: '#0866ff' }} />
            break
        case 'INSTANTCHAT':
            return <InstantChatIcon className="fileIcon" sx={{ color: '#5399DB' }} />
            break
        case 'MSTEAMS':
            return <MicrosoftIcon className="fileIcon" sx={{ color: '#6264A7' }} />
            break
        case 'EMAIL':
            return <EmailIcon className="fileIcon" sx={{ color: '#757575' }} />
            break
        case 'AUTOMATION':
            return <SmartToyIcon className="fileIcon" sx={{ color: '#757575' }} />
            break
        case 'INTEGRATION':
            return <IntegrationInstructionsIcon className="fileIcon" sx={{ color: '#5c736d' }} />
            break
        case 'API':
            return <ApiIcon className="fileIcon" sx={{ color: '#757575' }} />
            break
        default:
            return <WhatsAppIcon className="fileIcon" />
            break
    }
}

const calcdate = (row) => {
    return dayjs(`${row.date}T${row.time}`).format('DD/MM/YYYY HH:mm')
}

const HistoryRow = ({playingAudio, setPlayingAudio, smallLoading, setSmallLoading, enterprise, row, fetchHistoryMessages, i}) => {

    return (
        <>
            {row.platform_entrypoint !== 'INSTANTVOICE' ?
                <div>
                    <Box
                        key={i}
                        className="itemHistory"
                        onClick={() => fetchHistoryMessages({...row, hash: row.uniqueid})}
                        style={{ display: "inline-flex", alignItems: "center" }}
                    >
                        {getChatIcon(row.platform_entrypoint)}
                        {calcdate(row)}
                    </Box>
                </div>
                :
                <div>
                    {playingAudio.id == row.id ?
                        <>
                            {playingAudio.paused ?
                                <Box
                                    key={i}
                                    className="itemHistory"
                                    onClick={(event) => {
                                        playingAudio.audio.play()
                                        setPlayingAudio(p => ({ ...p, paused: false }))
                                    }}
                                    style={{ display: "inline-flex", alignItems: "center" }}
                                >
                                    <PhoneEnabledIcon className="fileIcon" sx={{ color: 'green' }} />
                                    {calcdate(row)}
                                </Box>
                                :
                                <Box
                                    key={i}
                                    className="itemHistory"
                                    onClick={(event) => {
                                        if (smallLoading)
                                            return
                                        playingAudio.audio.pause()
                                        setPlayingAudio(p => ({ ...p, paused: true }))
                                    }}
                                    style={{ display: "inline-flex", alignItems: "center" }}
                                >
                                    {smallLoading ? <AutorenewIcon className={'fileIcon spinner'} />
                                        :
                                        <PauseIcon className="fileIcon" />}
                                    {calcdate(row)}
                                </Box>
                            }
                        </>
                        :
                        <>
                            {row.status === 'Atendida' ?
                                <Box
                                    key={i}
                                    className="itemHistory"
                                    onClick={() => handleAudioPlay(row, enterprise, smallLoading, playingAudio, setSmallLoading, setPlayingAudio)}
                                    style={{ display: "inline-flex", alignItems: "center" }}
                                >
                                    <PhoneEnabledIcon className="fileIcon" sx={{ color: 'green' }} />
                                    {calcdate(row)}
                                </Box>
                                :
                                <Box
                                    key={i}
                                    className="itemHistory"
                                    onClick={() => { }}
                                    style={{ display: "inline-flex", alignItems: "center" }}
                                >
                                    <PhoneDisabledIcon className="fileIcon" sx={{ color: 'red' }} />
                                    {calcdate(row)}
                                </Box>
                            }

                        </>
                    }
                </div>
            }
        </>
    )
}

export default HistoryRow