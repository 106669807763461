import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Divider, 
    Tooltip, 
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import PropTypes from "prop-types";

import CloseIcon from '@mui/icons-material/Close';

import './styles.css'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MergeClientDialog = ({ open, clients, selectedClients, handleClose, handleSubmit }) => {

    const [client1, setClient1] = useState({})
    const [client2, setClient2] = useState({})
    const [finalClient, setFinalClient] = useState({})

    const renderKeys = {
        "name"                    : "Nome",
        "email"                   : "Email",
        "telephone"               : "Telefone",
        "contact_enterprise_name" : "Empresa",
        "cpf"                     : "CPF",
        "customer_code"           : "Código"
    }

    const prepareFinalClient = (client1, client2) => {
        const client = (client2.created_at < client1.created_at) ? client2 : client1
        const finalClient = {...client}
        Object.keys(finalClient).forEach((key) => {
            if (key === 'groups') {
                // Merge both clients groups
                finalClient[key] = [...client1['groups'], ...client2['groups']]
                return
            }
            if (! finalClient[key])
                finalClient[key] = client1[key] || client2[key]
        })
        setFinalClient(finalClient)
    }

    useEffect(() => {
        const client1 = clients.find((client) => client.id === selectedClients[0])
        const client2 = clients.find((client) => client.id === selectedClients[1])
        setClient1(client1)
        setClient2(client2)
        prepareFinalClient(client1, client2)
    }, [])

    const checkSelected = (key, value, priority) => {
        if (value === finalClient[key] && client1[key] === client2[key])
            return priority
        return value === finalClient[key]
    }

    const emptyBox = () => {
        return (
            <Box style={{ height: "20px" }}></Box>
        )
    }

    const updateFinalClient = (key, value, extra=null) => {
        setFinalClient(prev => ({ ...prev, [key]: value }));
        if (key === "contact_enterprise_name")
            setFinalClient(prev => ({ ...prev, contact_enterprise: extra }))
    }

    useEffect(() => {}, [finalClient]);

    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                disableEnforceFocus
            >
                <DialogTitle id="alert-dialog-slide-title" className={'group-flexbox'}>
                    <span>Mesclar clientes</span>
                    <Tooltip title="Fechar" >
                        <Button className="action-button">
                            <CloseIcon onClick={handleClose} />
                        </Button>
                    </Tooltip>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    A seguir, selecione o valor que deve ser priorizado no resultado final:
                    <Box className="merge-content">
                        <List>
                            {Object.keys(client1).map((key) => (
                                (key in renderKeys)
                                    ? <ListItem disablePadding key={`${key}-1`} className={checkSelected(key, client1[key], true) ? "item-selected" : ""}>
                                        <ListItemButton onClick={() => updateFinalClient(key, client1[key], client1["contact_enterprise"])}>
                                            <ListItemText 
                                                primary={renderKeys[key]} 
                                                secondary={Array.isArray(client1[key]) ? (client1[key][0] || emptyBox()) : (client1[key] || emptyBox())}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                    : null
                            ))}
                        </List>
                        <List>
                            {Object.keys(client2).map((key) => (
                                (key in renderKeys)
                                    ? <ListItem disablePadding key={`${key}-2`} className={checkSelected(key, client2[key], false) ? "item-selected" : ""}>
                                        <ListItemButton onClick={() => updateFinalClient(key, client2[key], client2["contact_enterprise"])}>
                                            <ListItemText 
                                                primary={renderKeys[key]} 
                                                secondary={Array.isArray(client2[key]) ? (client2[key][0] || emptyBox()) : (client2[key] || emptyBox())}
                                            />
                                        </ListItemButton>
                                    </ListItem>
                                    : null
                            ))}
                        </List>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <LoadingButton onClick={() => handleSubmit(client1, client2, finalClient)} color="primary">
                        Enviar
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

MergeClientDialog.propTypes = {
    open: PropTypes.bool,
    selectedClients: PropTypes.array,
    handleClose: PropTypes.func,
    handleSubmit: PropTypes.func
};

export default MergeClientDialog;
