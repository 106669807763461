import React from "react";
import { Box, Grid, IconButton, Tooltip, Menu, MenuItem } from "@mui/material";
import Typography from "../../../../Typography";
import PropTypes from "prop-types";
import { Reddit } from '@mui/icons-material';
import { CircularProgress } from "@mui/material";
import { emojiReplaceJSX } from "../../../../../utils/emojiDict";
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import SubjectIcon from '@mui/icons-material/Subject';
import EmailIcon from '@mui/icons-material/Email';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import "./styles.css";
import AttachmentItem from "./attachment";
import { calcDate } from "../NewMessageArea";

import DocumentIcon from "@mui/icons-material/InsertDriveFile"
import ChatImage from "../../../../ChatImage"

const urlRegex = /(http|ftp|https):\/\/([\w_-]+(?:\.[\w_-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])|www\.([\w_-]+(?:\.[\w_-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])/g

const getAttachments = (regex, value) => {
    const reference = value
    let data = []
    const response = reference.replace(regex, (a, b) => { data.push(JSON.parse(b)); return '' })
    return { response: response, data: data }
}

const specialReplace = (regex, value) => {

    const reference = value
    //matched -> the string that matched regex
    //the internals of the matched string ->
    //index: -> the index of the string that matched
    // replaced: the string without the matched string.

    let data = { matched: '', internal: '', index: '', replaced: '' }
    const replaced = reference.replace(regex, (a, b, c, d) => { data = { matched: a, internal: b, index: c }; return '' })
    data.replaced = replaced
    return data
}


class MessageItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            messageMenuAnchorEl: null
        }

        this.lineBreak_1 = '<br>';
        this.lineBreak_2 = '\n';
    }

    isThereSurvey = (message) => {
        const isThereSurvey =
            message.includes("msgini=") &&
            message.includes("msgend=") &&
            message.includes("text=") &&
            message.includes("id=");

        return isThereSurvey;
    }

    areThereButtons = (message) => {
        const areThereButtons =
            message.includes("title=") &&
            message.includes("][button") &&
            message.includes("action=");

        return areThereButtons;
    }

    parseMessage = (message) => {
        if (this.props.type === 'buttons' && this.areThereButtons(message)) {
            const title = message.split('title=')[1].split('][button')[0];
            const actions = this.getActions(message);

            return `${title}${this.lineBreak_1}${actions.join(', ')}`;

        }
        else if (this.props.type === 'survey' && this.isThereSurvey(message)) {
            // const msgIni = message.split('msgini=')[1].split('msgend=')[0];
            // const text = message.split('text=')[1].split('id=')[0];

            return 'Início da pesquisa de satisfação.' // `${msgIni}${this.lineBreak_1}${text}`;
        }

        return message;
    }

    getActions = (message) => {
        const parts = message.split('action=').slice(1);

        const actions = [];
        parts.forEach((part) => {
            actions.push(part.substring(0, part.indexOf(']')));
        });

        return actions;
    }

    isSpecialMessage = () => {
        return this.props.type === 'buttons' || this.props.type === 'survey' || this.props.type === 'emailinbox';
    }

    sendMessageToAssistant = (message) => {
        window.openAssistant()
        window.submitMessage(message)
    }
    clearInbox = (inbox) => {
        const data = inbox?.match(/<([^>]+)>/g)?.map(match => match.replace(/<|>/g, ''))
        if (data)
            return data
        return inbox
    }

    isDocumentMessage = () => {
        const message = this.props.message.message
        return message.startsWith("[url=") && message.endsWith("]Baixar arquivo[/url]")
    }

    isImageMessage = () => {
        const message = this.props.message.message
        return message.startsWith("[img]") && message.endsWith("[/img]")
    }

    displayMessage = () => {
        let props_message = this.props.message.message;

        if (this.props.message.status === 'DELETED' && !this.props.media)
            props_message = 'Essa mensagem foi removida pelo usuário.'

        if (this.props.receiveURL === false)
            props_message = props_message.replaceAll(urlRegex, ' - URL Bloqueada -')

        if (this.isDocumentMessage()) {
            const mediaUrl = this.props.message.message.replace("[url=", "").replace("]Baixar arquivo[/url]", "")
            const mediaName = mediaUrl.split("/").pop().split("-").pop()
            return (
                <Box className="messageItem-box-2" style={{ display: "flex", gap: ".25rem" }}>
                    <DocumentIcon style={{ color: "#21446C" }}/>
                    <a target="_blank" href={mediaUrl} style={{ color: "white", textDecoration: "none" }}>{mediaName}</a>
                </Box>
            )
        }

        if (this.isImageMessage()) {
            const mediaUrl = this.props.message.message.replace("[img]", "").replace("[/img]", "")
            return (
                <Box sx={{ maxWidth: "250px" }}>
                    <img 
                        src={mediaUrl}
                        style={{ width: "100%", height: "100%", objectFit: "cover" }} 
                        alt="media"
                    />
                </Box>
            )
        }

        if (this.isSpecialMessage()) {
            if (this.props.type === 'survey')
                props_message = props_message.replaceAll('[survey]', '').replaceAll('[/survey]', '')
            if (this.props.type === 'emailinbox') {
                const inbox = specialReplace(/\(emailinbox\)(.*?)\(\/emailinbox\)/g, props_message)
                const subject = specialReplace(/\(emailsubject\)(.*?)\(\/emailsubject\)/g, inbox.replaced)
                const unclear_body = subject.replaced
                const attachments_obj = getAttachments(/\(emailattachment\)(.*?)\(\/emailattachment\)/g, unclear_body)
                const body = attachments_obj.response.replace(/\## - (.*?) - ##/, '')
                const attachments = attachments_obj.data

                return (
                    <Box className="messageItem-box-2" sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                        {inbox?.matched && <Box sx={{ display: 'flex', gap: '.5rem' }}>
                            <Tooltip title='Destinatário'><EmailIcon sx={{ color: 'white' }} /></Tooltip>
                            <Typography text={this.clearInbox(inbox.internal)}
                                color="white"
                                align="start"
                                fontWeight='bold'
                                justifyContent="left"
                            />
                        </Box>}
                        {subject?.matched && <Box sx={{ display: 'flex', gap: '.5rem' }}>
                            <Tooltip title='Assunto'><SubjectIcon sx={{ color: 'white' }} /></Tooltip>
                            <Typography text={subject?.internal}
                                color="white"
                                align="start"
                                fontWeight='bold'
                                justifyContent="left"
                            /></Box>}
                        <Box sx={{ display: 'flex', gap: '.5rem' }}>
                            <Box sx={{ paddingLeft: '1.5rem' }}></Box>
                            {this.parseMessage(body).split(this.lineBreak_1).map(message => (
                                <Typography text={emojiReplaceJSX(message)}
                                    color="white"
                                    align="start"
                                    justifyContent="left"
                                />
                            )
                            )}
                        </Box>
                        {attachments.length > 0 && <Box className='message-email-attachments'>
                            {attachments.map(attachment => <AttachmentItem data={attachment} />)}
                        </Box>}
                    </Box>
                )
            }

            return (
                <Box className="messageItem-box-2">
                    {this.parseMessage(props_message).split(this.lineBreak_1).map(message => (
                        <Typography text={message}
                            color="white"
                            align="start"
                            justifyContent="left"
                        />
                    )
                    )}
                </Box>
            );
        } else if (!props_message.includes(this.lineBreak_2)) {
            return (
                <Box className="messageItem-box-2">
                    {this.props.message.msg == 'SENDINGMEDIAMESSAGE' ?
                        <>
                            {this.props.message.expired ?
                                <Typography text={'Envio falhou.'}
                                    color="white"
                                    align="start"
                                    justifyContent="left"
                                />
                                :
                                <CircularProgress sx={{ color: 'white', width: '18px', padding: '.5rem' }} />
                            }
                        </>
                        :
                        <Typography text={emojiReplaceJSX(props_message)}
                            color="white"
                            align="start"
                            justifyContent="left"
                        />
                    }

                </Box>
            );
        } else if (props_message.includes(this.lineBreak_2)) {
            return (
                <Box className="messageItem-box-2">
                    {props_message.split(this.lineBreak_2).map(message => (
                        message !== "" ?
                            (<Typography text={emojiReplaceJSX(message)}
                                color="white"
                                align="start"
                                justifyContent="left"
                            />) :
                            (<br />)
                    ))}
                </Box>
            );
        }
    }

    handleMessageMenuClick = (event) => {
        this.setState({ messageMenuAnchorEl: event.currentTarget })
    }

    handleMessageMenuClose = () => {
        this.setState({ messageMenuAnchorEl: null })
    }

    replyMessage = (message) => {
        this.props.setReplying(message)
    }

    declareColor = () => {
        const chatid = this.props.activeChat?.lh_chat_id || this.props.activeChat?.id
        return `${this.props.user === -1 ? 'bgMessage bgMessage-chatbot' : 'bgMessage'} 
        ${this.props.message?.chat && (chatid !== this.props.message.chat) ? 'different-chat': ''}`
    }

    render() {
        let replyFrom = null
        if (this.props.message.reply_from) {
            replyFrom = JSON.parse(JSON.stringify(this.props.message))
            replyFrom.message = this.props.message.reply_from
            replyFrom.reply_from = null
        }
        
        return (
            <Box 
                className={this.props.replyFrom ? "messageItem-box-1 reply-from" : "messageItem-box-1"} 
                justifyContent={this.props.replyFrom ? 'flex-start' : this.props.user !== 0 ? 'flex-end' : 'flex-start'}
            >
                <Box 
                    bgcolor={this.props.message.expired ? '#909eac' : this.props.user !== 0 ? '#5399DB' : '#21446c'}
                    className={this.declareColor()}
                    id={this.props.replyFrom ? 'left-user' : this.props.user !== 0 ? 'right-user' : 'left-user'}
                >
                    <Tooltip placement='left' title={! this.props.replyFrom && this.props.message?.agent_name ? `Enviado por: ${this.props.message?.agent_name}` : ''}>
                        <span>
                            {! this.props.replyFrom && ((this.props.user === 0 && this.props.platform !== 'EMAIL') || this.props.platform === 'WHATSAPP') &&
                                <IconButton
                                    className="message-actions"
                                    aria-controls="dropdown-menu"
                                    aria-haspopup="true"
                                    sx={{ position: 'absolute', top: '-3px', right: '0px', cursor: 'pointer' }}
                                    onClick={this.handleMessageMenuClick}
                                    aria-expanded={Boolean(this.state.messageMenuAnchorEl)}
                                >
                                    <ExpandMoreIcon sx={{
                                        color: 'white',
                                        fontSize: '1.2rem',
                                        transform: 'rotate(180deg) rotateX(180deg)'
                                    }}/>
                                </IconButton>
                            }
                            <Menu
                                id="dropdown-menu"
                                anchorEl={this.state.messageMenuAnchorEl}
                                open={Boolean(this.state.messageMenuAnchorEl)}
                                onClose={this.handleMessageMenuClose}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                MenuListProps={{
                                    'aria-labelledby': 'dropdown-button',
                                }}
                            >
                                {this.props.user === 0 && this.props.platform !== 'EMAIL' &&
                                    <MenuItem onClick={() => {
                                        this.sendMessageToAssistant(this.props.message.message)
                                        this.handleMessageMenuClose()
                                    }}>Perguntar ao assistente</MenuItem>
                                }
                                {this.props.platform === 'WHATSAPP' &&
                                    <MenuItem onClick={() => {
                                        this.replyMessage(this.props.message)
                                        this.handleMessageMenuClose()
                                    }}>Responder</MenuItem>
                                }
                            </Menu>
                            {replyFrom &&
                                <MessageItem key={`reply-from-${replyFrom.id}`}
                                    message={replyFrom}
                                    user={replyFrom.user_id}
                                    replyFrom={true}
                                    activeChat={this.props.activeChat}
                                />
                            }
                            {this.displayMessage()}
                            {! this.props.replyFrom &&
                                <Grid container justifyContent="center">
                                    <Grid item xs={this.props.user == -1 ? 6 : 0}>
                                        {this.props.user == -1 &&
                                            <Box id="bgBot">
                                                <Reddit id="iconBot" />
                                                <Typography text=" ChatBot"
                                                    color="white"
                                                    variant="caption"
                                                    align="left"
                                                    justifyContent="left"
                                                />
                                            </Box>
                                        }
                                    </Grid>
                                    <Grid item xs={this.props.user == -1 ? 6 : 12}>
                                        <Box className="bgTime">
                                            <Tooltip placement='bottom' title={calcDate(this.props.message.time, 'DD/MM/YYYY HH:mm')}>
                                                <span>
                                                    <Typography text={`${this.props.message.iso_time}`}
                                                        color="white"
                                                        variant="caption"
                                                        align="left"
                                                        justifyContent="left"
                                                    />
                                                </span>
                                            </Tooltip>
                                            {['WHATSAPP', 'WHATSAPP_ATIVO'].includes(this.props.platform) && this.props.message.user_id !== 0 && this.props.message.iso_time !== '--:--' &&
                                                <DoneAllIcon className={this.props.message.is_client_read ? "done-all-icon message-read" : "done-all-icon"}/>
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            }
                        </span>
                    </Tooltip>
                </Box>
            </Box >
        );
    }
}

export default MessageItem;

