import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Tooltip } from "@mui/material";
import MessageItem from "../Chat/MessagesArea/ChatWindow/MessageItem";

import makeStyles from '@mui/styles/makeStyles';

const ChatImage = ({ key, src, user, message, queuesName, platform, activeChat }) => {

    const [error, setError] = useState(false)

    const useStyles = makeStyles((theme) => {
        return {
            image: {
                maxWidth: '250px',
                border: user !== 0 ? '3px solid #21446C' : '3px solid #f1f0f7',
                borderRadius: '5px',
                marginTop: '5px',
                marginBottom: '5px'
            }
        }
    })

    const classes = useStyles();

    return (

        <>
            {!error ?

                <Box display="flex"
                    justifyContent={user !== 0 ? 'flex-end' : 'flex-start'}
                >
                    <Tooltip placement='left' title={message?.agent_name ? `Enviado por: ${message?.agent_name}` : ''}>
                        <a href={src} target="_blank">
                            <img onError={() => { setError(true) }} className={classes.image} src={src} />
                        </a>
                    </Tooltip>
                </Box>
                :
                <MessageItem
                    key={key}
                    message={{ ...message, message: 'Essa imagem não está mais disponível' }}
                    user={user}
                    queuesName={queuesName}
                    platform={platform}
                    media={true}
                    activeChat={activeChat}
                />
            }
        </>


    )
}

ChatImage.PropTypes = {
    src: PropTypes.any,
    user: PropTypes.int
};

export default ChatImage;
