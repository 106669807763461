import React, { useState, useEffect } from 'react'

import { NumericFormat } from 'react-number-format';

import { IMaskInput } from "react-imask"

import InputMask from "react-input-mask"


export const NumericFormatInput = React.forwardRef(function NumericFormatCustom(props, ref) {
    const {onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            prefix={'R$ '}
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale
            allowNegative={false}
            valueIsNumericString
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value || 0,
                    },
                });
            }}
        />
    )
})

export const CnpjMask = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props
    return (
        <IMaskInput
            {...other}
            mask="00.000.000/0000-00"
            definitions={{ "#": /[0-9]/ }}
            inputRef={ref}
            onAccept={(value) => {
                if (value !== props.value) {
                    onChange({ target: { name: props.name, value } })
                }
            }}
            overwrite
        />
    )
})

export const TelephoneMask = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props
    return (
        <IMaskInput
            {...other}
            mask={[
                { mask: '(00) 0000-0000', overwrite: true }, 
                { mask: '(00) 00000-0000', overwrite: true }
            ]}
            definitions={{ "#": /[0-9]/ }}
            inputRef={ref}
            onAccept={(value) => {
                if (value !== props.value) {
                    onChange({ target: { name: props.name, value } })
                }
            }}
            overwrite
        />
    )
})

export const PostalCodeMask = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props
    return (
        <IMaskInput
            {...other}
            mask="00000-000"
            definitions={{ "#": /[0-9]/ }}
            inputRef={ref}
            onAccept={(value) => {
                if (value !== props.value) {  // Avoid unnecessary updates
                    onChange({ target: { name: props.name, value } })
                }
            }}
            overwrite
        />
    )
})

export const CpfMask = React.forwardRef((props, ref) => {
    const { onChange, ...other } = props;

    return (
        <InputMask
            {...other}
            mask="999.999.999-99"
            onChange={(e) => onChange(e)}
        >
            {(inputProps) => <input {...inputProps} ref={ref} />}
        </InputMask>
    )
})
