import React from 'react';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import {
  DialogActions,
  DialogContent,
  Box,
  TextField,
  Switch,
  DialogTitle,
  Button,
  Slide,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Divider,
  Radio,
  RadioGroup,
} from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';

import MultipleSelections from '../../../Input/MultipleSelections';

import useNotification from "../../../Notification";

import "./styles.css";

import { getSession } from "../../../../auth";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Modal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      groups: [],
      isActive: false,
      canCreateTemplate: false,
      canCreateContactGroup: true,
      number: '',
      name: '',
      email: '',
      loading: false,
      isVoice: false,
      enterpriseHasVoice: false,
      enterpriseHasAssistant: false,
      userHasAssistant: false,
      first_name: "",
      last_name: "",
      nickname: "",
      body: {},
      selectedOption: '',
      showInputs: false,
      instantvoice: false,
      externalServerUri: '', // Estado para a URL
      externalUsername: '', // Estado para o nome de usuário
      externalPassword: '', // Estado para a senha
      instantvoiceAccountcode: '',
      instantvoiceAgentCode: '',
      instantvoiceApiKey: '',
      instantvoiceUrl: '',
      create_contacts: false,
      change_contacts: false,
      change_campaigns: false,
    }

    this.groups = [
      'administrador',
      'supervisor',
      'operador'
    ];

    this.dialogTitle = null;
  }

  onChangeName = (event) => {
    this.setState({ name: event.target.value });
  }

  onChangeEmail = (event) => {
    this.setState({ email: event.target.value });
  }

  onChangeSelectedOption = (event) => {
    const value = event.target.value;
    this.setState({ selectedOption: value, showInputs: value === 'external' });
    this.state.body['enterprise_has_voice'] = this.state.enterpriseHasVoice;
    this.state.body['is_voice'] = true;
  };

  onChangeURL = (event) => {
    this.state.body['enterprise_has_voice'] = this.state.enterpriseHasVoice;
    this.state.body['is_voice'] = true;
    this.setState({ externalServerUri: event.target.value });
  };

  onChangeUsername = (event) => {
    this.state.body['enterprise_has_voice'] = this.state.enterpriseHasVoice;
    this.state.body['is_voice'] = true;
    this.setState({ externalUsername: event.target.value });
  };

  onChangePassword = (event) => {
    this.state.body['enterprise_has_voice'] = this.state.enterpriseHasVoice;
    this.state.body['is_voice'] = true;
    this.setState({ externalPassword: event.target.value });
  };

  setChecked = (condition) => {
    this.state.body['is_active'] = condition;
    this.setState({ isActive: condition });
  }

  setCanCreateTemplate = (value) => {
    this.state.body["can_create_template"] = value
    this.setState({ canCreateTemplate: value })
  }

  setCanCreateContactGroup = (value) => {
    this.state.body["can_create_contact_group"] = value
    this.setState({ canCreateContactGroup: value })
  }

  setCheckedVoice = (condition) => {
    this.state.body['enterprise_has_voice'] = this.state.enterpriseHasVoice;
    this.state.body['is_voice'] = condition;
    this.state.body['extension_type'] = this.state.selectedOption;
    this.setState({ isVoice: condition });
  }

  setUserHasAssistant = (condition) => {
    if (!this.state.body.hasOwnProperty('profile'))
      this.state.body['profile'] = {};
    this.state.body['profile']['has_assistant'] = condition;
    this.setState({ userHasAssistant: condition });
  }

  setExternalInputs = () => {
    if (this.state.selectedOption == 'internal') {
      this.state.body['external_server_uri'] = '';
      this.state.body['external_username'] = '';
      this.state.body['external_password'] = '';
      this.state.body['extension_type'] = 'internal';
    } else if (this.state.selectedOption == 'external') {
      this.state.body['external_server_uri'] = this.state.externalServerUri;
      this.state.body['external_username'] = this.state.externalUsername;
      this.state.body['external_password'] = this.state.externalPassword;
      this.state.body['extension_type'] = 'external';
    }
  }

  saveUser = async () => {
    this.setState({ loading: true });
    if (this.props.showUpdateUser) {
      this.setExternalInputs();
      this.state.body["instantvoice_accountcode"] = this.state.instantvoiceAccountcode
      this.state.body["instantvoice_agent_code"] = this.state.instantvoiceAgentCode
      this.state.body["instantvoice_api_key"] = this.state.instantvoiceApiKey
      this.state.body["instantvoice_url"] = this.state.instantvoiceUrl
      this.state.body["create_contacts"] = this.state.create_contacts
      this.state.body["change_contacts"] = this.state.change_contacts
      this.state.body["change_groups"] = this.state.change_groups
      this.state.body["change_campaigns"] = this.state.change_campaigns
      await this.props.updateUser(this.props.user.id, this.state.body);
    } else {
      const name = this.state.first_name;
      const surname = this.state.last_name
      const email = this.state.email;

      if (!name || !email) {
        useNotification(
          'Ops!',
          'Os campos não podem ser vazios.',
          'danger'
        );
        return;
      }

      const body = {
        first_name: name,
        last_name: surname,
        email: email,
        create_contacts: this.state.create_contacts,
        change_contacts: this.state.change_contacts,
        change_campaigns: this.state.change_campaigns,
        can_create_template: this.state.canCreateTemplate,
        can_create_contact_group: this.state.canCreateContactGroup
      };

      this.props.createUser(body);
    }
  };

  componentWillMount() {
    const user = this.props.user;
    const user_session = getSession();
    let voice = false;

    if (user && user.groups) {
      const groups = [];

      user.groups.map(group => {
        groups.push(group.name);
      });

      if (user_session.profile.enterprise.has_voice) {
        if (user.profile.agent.preferred_interface)
          voice = true;
        else
          voice = false;
      } else
        voice = false;

      this.state.groups = groups;
      this.state.isActive = user.is_active;
      this.state.isVoice = voice;

      if (this.props.showUpdateUser !== undefined) {
        this.state.canCreateTemplate = user.groups.some(group => ['administrador', 'supervisor'].includes(group.name)) || user.profile.can_create_template
        this.state.canCreateContactGroup = user.groups.some(group => ['administrador', 'supervisor'].includes(group.name)) || user.profile.can_create_contact_group
      }

      this.state.body = {
        first_name: user.first_name,
        last_name: user.last_name,
        nickname: user.profile.nickname
      }

      this.dialogTitle = user.username;
      this.state.userHasAssistant = user.profile.has_assistant;
      this.state.enterpriseHasVoice = user_session.profile.enterprise.has_voice;
      this.state.enterpriseHasAssistant = user_session.profile.enterprise.has_assistant;
      this.state.externalServerUri = user.profile.agent.external_server_uri;
      this.state.externalUsername = user.profile.agent.external_username;
      this.state.externalPassword = user.profile.agent.external_password;
      this.state.create_contacts = user.profile.create_contacts
      this.state.change_contacts = user.profile.change_contacts
      this.state.change_groups = user.profile.change_groups
      this.state.change_campaigns = user.profile.change_campaigns
      this.state.showInputs = user.profile.agent.external_server_uri != '';
      this.state.selectedOption = user.profile.agent.external_server_uri != '' ? "external" : "internal";

      this.state.instantvoice = user.profile.instantvoice_accountcode || user.profile.instantvoice_agent_code || user.profile.instantvoice_api_key || user.profile.instantvoice_url
      this.state.instantvoiceAccountcode = user.profile.instantvoice_accountcode
      this.state.instantvoiceAgentCode = user.profile.instantvoice_agent_code
      this.state.instantvoiceApiKey = user.profile.instantvoice_api_key
      this.state.instantvoiceUrl = user.profile.instantvoice_url

    }
    else {
      this.state.create_contacts = user_session.profile.enterprise.settings.agent_can_create_contact
      this.state.change_contacts = user_session.profile.enterprise.settings.agent_can_update_contact
      this.state.change_groups = user_session.profile.enterprise.settings.agent_can_create_segments
      this.state.change_campaigns = user_session.profile.enterprise.settings.agent_can_create_campaigns
    }
  }


  render() {
    return (
      <>
        <DialogTitle className="group-flexbox">
          <span>{this.props.showUpdateUser ? this.dialogTitle : 'Criar novo usuário'} </span>
          <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={this.props.handleClose} ><CloseIcon /></Button>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {this.props.showUpdateUser &&
            <>
              <Box className="input-users-modal" sx={{ gap: "1rem", display: "flex" }}>
                <TextField
                  size="small"
                  label="Nome"
                  fullWidth
                  type="text"
                  variant="outlined"
                  value={this.state.body.first_name}
                  onChange={(e) => this.setState({ body: { ...this.state.body, first_name: e.target.value } })}
                />
                <TextField
                  size="small"
                  label="Sobrenome"
                  fullWidth
                  type="text"
                  variant="outlined"
                  value={this.state.body.last_name}
                  onChange={(e) => this.setState({ body: { ...this.state.body, last_name: e.target.value } })}
                />
                <TextField
                  size="small"
                  label="Apelido"
                  fullWidth
                  type="text"
                  variant="outlined"
                  value={this.state.body.nickname}
                  onChange={(e) => this.setState({ body: { ...this.state.body, nickname: e.target.value } })}
                />
              </Box>
              <MultipleSelections
                title="Grupos"
                selectedData={this.state.groups}
                dataProp={this.groups}
                getSelect={(groups) => { this.setState({ body: { ...this.state.body, groups: groups }, groups: groups }) }}
                colors={this.props.groupColors}
              />

              <Box>
                {getSession().id !== this.props.user.id &&
                  <FormControl component="fieldset" style={{ marginTop: "5px" }}>
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        label="Ativo"
                        labelPlacement="end"
                        control={
                          <Switch
                            color="primary"
                            checked={this.state.isActive}
                            onChange={() => this.setChecked(!this.state.isActive)}
                          />
                        }
                      />
                    </FormGroup>
                  </FormControl>
                }
              </Box>
              <FormControl component="fieldset" >
                <FormGroup aria-label="position" row style={{ flexDirection: 'column', display: 'flex' }}>
                  {(this.state.groups?.filter(group => ['administrador', 'supervisor'].includes(group)).length === 0) &&
                    <>
                      <FormControlLabel
                        label="Pode Criar Contatos"
                        labelPlacement="end"
                        control={
                          <Switch
                            color="primary"
                            checked={this.state.create_contacts}
                            onChange={() => this.setState({ create_contacts: !this.state.create_contacts })}
                          />
                        }
                      />
                      <FormControlLabel
                        label="Pode Editar Contatos"
                        labelPlacement="end"
                        control={
                          <Switch
                            color="primary"
                            checked={this.state.change_contacts}
                            onChange={() => this.setState({ change_contacts: !this.state.change_contacts })}
                          />
                        }
                      />

                      <FormControlLabel
                        label="Pode Criar/Editar Segmentos"
                        labelPlacement="end"
                        control={
                          <Switch
                            color="primary"
                            checked={this.state.canCreateContactGroup}
                            onChange={() => this.setCanCreateContactGroup(!this.state.canCreateContactGroup)}
                          />
                        }
                      />

                      <FormControlLabel
                        label="Pode Criar/Editar Templates"
                        labelPlacement="end"
                        control={
                          <Switch
                            color="primary"
                            checked={this.state.canCreateTemplate}
                            onChange={() => this.setCanCreateTemplate(!this.state.canCreateTemplate)}
                          />
                        }
                      />

                      <FormControlLabel
                        label="Pode Criar/Editar Campanhas"
                        labelPlacement="end"
                        control={
                          <Switch
                            color="primary"
                            checked={this.state.change_campaigns}
                            onChange={() => this.setState({ change_campaigns: !this.state.change_campaigns })}
                          />
                        }
                      />
                    </>
                  }
                  {this.state.enterpriseHasAssistant &&
                    <FormControlLabel
                      label="Cortex"
                      labelPlacement="end"
                      control={
                        <Switch
                          color="primary"
                          checked={this.state.userHasAssistant}
                          onChange={() => this.setUserHasAssistant(!this.state.userHasAssistant)}
                        />
                      }
                    />}

                  {this.state.enterpriseHasVoice &&
                    <FormControlLabel
                      label="Voz"
                      labelPlacement="end"
                      control={
                        <Switch
                          color="primary"
                          checked={this.state.isVoice}
                          onChange={() => this.setCheckedVoice(!this.state.isVoice)}
                        />
                      }
                    />
                  }

                </FormGroup>
              </FormControl>
            </>
          }
          {this.state.isVoice &&
            <>
              <Box className="voice-settings">
                <FormControl component="fieldset">
                  <RadioGroup className="voice-settings-options"
                    aria-label="opções"
                    name="opcoes"
                    value={this.state.selectedOption}
                    onChange={this.onChangeSelectedOption}
                  >
                    <FormControlLabel
                      value="internal"
                      control={<Radio />}
                      label="Ramal Interno"
                    />
                    <FormControlLabel
                      value="external"
                      control={<Radio />}
                      label="Ramal Externo"
                    />
                  </RadioGroup>
                </FormControl>
                {this.state.showInputs && (
                  <>
                    <Box className="input-users-modal" sx={{ gap: "1rem", display: "flex", marginTop: "1rem" }}>
                      <TextField
                        size="small"
                        label="URL do Ramal"
                        variant="outlined"
                        fullWidth
                        value={this.state.externalServerUri}
                        onChange={this.onChangeURL}
                      />
                    </Box>
                    <Box className="input-users-modal" sx={{ gap: "1rem", display: "flex" }}>
                      <TextField
                        size="small"
                        label="Usuário"
                        variant="outlined"
                        fullWidth
                        value={this.state.externalUsername}
                        onChange={this.onChangeUsername}
                      />
                      <TextField
                        size="small"
                        label="Senha"
                        variant="outlined"
                        type="password"
                        fullWidth
                        value={this.state.externalPassword}
                        onChange={this.onChangePassword}
                      />
                    </Box>
                    <FormControl component="fieldset" style={{ marginTop: "5px" }}>
                      <FormGroup aria-label="position" row>
                        <FormControlLabel sx={{ marginLeft: ".25rem" }}
                          label="Integração InstantVoice"
                          labelPlacement="start"
                          control={
                            <Switch
                              color="primary"
                              checked={this.state.instantvoice}
                              onChange={() => this.setState({ instantvoice: !this.state.instantvoice })}
                            />
                          }
                        />
                      </FormGroup>
                    </FormControl>
                    {this.state.instantvoice &&
                      <>
                        <Box className="input-users-modal" sx={{ gap: "1rem", display: "flex", marginTop: "1rem" }}>
                          <TextField
                            size="small"
                            label="URL do InstantVoice"
                            variant="outlined"
                            fullWidth
                            value={this.state.instantvoiceUrl}
                            onChange={(event) => {
                              this.state.body['instantvoice_url'] = event.target.value
                              this.setState({ instantvoiceUrl: event.target.value })
                            }}
                          />
                          <TextField
                            size="small"
                            label="Accountcode"
                            variant="outlined"
                            fullWidth
                            value={this.state.instantvoiceAccountcode}
                            onChange={(event) => {
                              this.state.body['instantvoice_accountcode'] = event.target.value
                              this.setState({ instantvoiceAccountcode: event.target.value })
                            }}
                          />
                        </Box>
                        <Box className="input-users-modal" sx={{ gap: "1rem", display: "flex" }}>
                          <TextField
                            size="small"
                            label="Chave de API"
                            variant="outlined"
                            fullWidth
                            value={this.state.instantvoiceApiKey}
                            onChange={(event) => {
                              this.state.body['instantvoice_api_key'] = event.target.value
                              this.setState({ instantvoiceApiKey: event.target.value })
                            }}
                          />
                          <TextField
                            size="small"
                            label="Código do operador"
                            variant="outlined"
                            fullWidth
                            value={this.state.instantvoiceAgentCode}
                            onChange={(event) => {
                              this.state.body['instantvoice_agent_code'] = event.target.value
                              this.setState({ instantvoiceAgentCode: event.target.value })
                            }}
                          />
                        </Box>
                      </>
                    }
                  </>
                )}
              </Box>
            </>
          }

          {!this.props.showUpdateUser &&
            <>
              <Box className="input-users-modal" sx={{ gap: "1rem", display: "flex", flexDirection: 'column' }}>
                <TextField
                  size="small"
                  label="Nome"
                  fullWidth
                  type="text"
                  variant="outlined"
                  value={this.state.first_name}
                  onChange={(e) => this.setState({ first_name: e.target.value })}
                />
                <TextField
                  size="small"
                  label="Sobrenome"
                  fullWidth
                  type="text"
                  variant="outlined"
                  value={this.state.last_name}
                  onChange={(e) => this.setState({ last_name: e.target.value })}
                />
              </Box>
              <Box className="input-users-modal">
                <TextField
                  size="small"
                  label="E-mail"
                  fullWidth
                  type="email"
                  variant="outlined"
                  defaultValue={this.state.email}
                  onChange={this.onChangeEmail}
                />
              </Box>
              <FormControl component="fieldset" >
                <FormGroup aria-label="position" row style={{ marginTop: "5px", display: 'flex', flexDirection: 'column' }}>
                  <FormControlLabel
                    label="Pode Criar Contatos"
                    labelPlacement="end"
                    control={
                      <Switch
                        color="primary"
                        checked={this.state.create_contacts}
                        onChange={() => this.setState({ create_contacts: !this.state.create_contacts })}
                      />
                    }
                  />
                  <FormControlLabel
                    label="Pode Editar Contatos"
                    labelPlacement="end"
                    control={
                      <Switch
                        color="primary"
                        checked={this.state.change_contacts}
                        onChange={() => this.setState({ change_contacts: !this.state.change_contacts })}
                      />
                    }
                  />

                  <FormControlLabel
                    label="Pode Criar/Editar Segmentos"
                    labelPlacement="end"
                    control={
                      <Switch
                        color="primary"
                        checked={this.state.canCreateContactGroup}
                        onChange={() => this.setCanCreateContactGroup(!this.state.canCreateContactGroup)}
                      />
                    }
                  />

                  <FormControlLabel
                    label="Pode Criar/Editar Templates"
                    labelPlacement="end"
                    control={
                      <Switch
                        color="primary"
                        checked={this.state.canCreateTemplate}
                        onChange={() => this.setCanCreateTemplate(!this.state.canCreateTemplate)}
                      />
                    }
                  />

                  <FormControlLabel
                    label="Pode Criar/Editar Campanhas"
                    labelPlacement="end"
                    control={
                      <Switch
                        color="primary"
                        checked={this.state.change_campaigns}
                        onChange={() => this.setState({ change_campaigns: !this.state.change_campaigns })}
                      />
                    }
                  />
                </FormGroup>
              </FormControl>
            </>
          }
          <Box className="user-footer">
            <LoadingButton className="button-created"
              onClick={this.props.handleClose}
            >
              Cancelar
            </LoadingButton>
            <LoadingButton className="button-created"
              onClick={() => this.saveUser()}
              disabled={!this.props.showUpdateUser && (!this.state.first_name || !this.state.email)}
              loading={false}
            >
              Salvar
            </LoadingButton>
          </Box>
        </DialogContent >
      </>
    );
  }
}

export default Modal;