import React, { useMemo, useState, useEffect } from "react"

import {
    Box,
    Tooltip,
    Button,
    Typography,
    List,
    ListItem,
    ListItemText
} from "@mui/material"

import LoadingButton from '@mui/lab/LoadingButton'

import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    ptBR
} from "@mui/x-data-grid-pro"

import {
    createTheme,
    ThemeProvider
} from "@mui/material/styles"

import { ptBR as corePtBR } from "@mui/material/locale"

import AddCircleIcon from "@mui/icons-material/AddCircle"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import GroupsIcon from "@mui/icons-material/Groups"
import EmailIcon from "@mui/icons-material/Email"
import PhoneIcon from "@mui/icons-material/Phone"

import { stringOperators } from "../../../../utils/filterOperators"
import { getSession } from "../../../../auth"

import ContactEnterpriseDialog from "../../../Dialogs/ContactEnterprise"
import ConfirmationDialogCaptcha from "../../../Dialogs/ConfimationDialogCaptcha"
import useNotification from "../../../Notification"
import api from "../../../../api"

export const getEnterpriseColumns = (setSelectedEnterprise, setShowDeleteDialog, setShowContactEnterpriseDialog, updateSelectedTab, getClients) => {
    return [
        { field: 'name', headerName: 'Nome', flex: 1, filterOperators: stringOperators },
        { field: 'social_reason', headerName: 'Razão Social', flex: 1, filterOperators: stringOperators },
        { field: 'telephone', headerName: 'Telefone', flex: 1, filterOperators: stringOperators },
        { field: 'email', headerName: 'E-mail', flex: 1, filterOperators: stringOperators },
        { field: 'cnpj', headerName: 'CNPJ', flex: 1, filterOperators: stringOperators },
        { field: 'address', headerName: 'Endereço', flex: 1, hide: true, filterOperators: stringOperators },
        { field: 'address_number', headerName: 'Número', flex: 1, hide: true, filterOperators: stringOperators },
        { field: 'address_extra', headerName: 'Complemento', flex: 1, hide: true, filterOperators: stringOperators },
        { field: 'neighborhood', headerName: 'Bairro', flex: 1, hide: true, filterOperators: stringOperators },
        { field: 'city', headerName: 'Cidade', flex: 1, hide: true, filterOperators: stringOperators },
        { field: 'estate', headerName: 'Estado', flex: 1, hide: true, filterOperators: stringOperators },
        { field: 'postal_code', headerName: 'CEP', flex: 1, hide: true, filterOperators: stringOperators },
        { field: 'action', headerName: 'Ações', flex: .5, sortable: false, filterable: false,
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Ver Contatos" interactive>
                        <Button className="action-button" onClick={() => {
                            updateSelectedTab("clients")
                            getClients("filter", 25, 0, {
                                items: [{
                                    columnField: "contact_enterprise_name",
                                    operatorValue: "equals",
                                    value: params.row.name
                                }]
                            })
                        }}>
                            <GroupsIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Editar" interactive>
                        <Button className="action-button" onClick={() => { setSelectedEnterprise(params.row); setShowContactEnterpriseDialog(true); }}>
                            <EditIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Excluir" interactive>
                        <Button className="action-button delete" onClick={() => { setSelectedEnterprise(params.row); setShowDeleteDialog(true); }}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                </Box>
            )
        }
    ]
}

const CustomToolbar = (setSelectedEnterprise, setShowContactEnterpriseDialog, setShowMassDeleteEnterpriseDialog, selectedEnterprises) => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
            <LoadingButton component="label" startIcon={<AddCircleIcon />} onClick={() => { setSelectedEnterprise(null); setShowContactEnterpriseDialog(true) }} size="small">Adicionar</LoadingButton>
            <LoadingButton component="label" startIcon={<DeleteIcon />} onClick={() => {
                if (selectedEnterprises.length === 0) {
                    useNotification(
                        "Atenção!",
                        "Seleciona uma ou mais empresas para excluir.",
                        "warning"
                    )
                    return
                }
                setShowMassDeleteEnterpriseDialog(true)
            }} size="small">Excluir</LoadingButton>
        </GridToolbarContainer>
    )
}

const theme = createTheme({
    palette: {
        primary: { main: "#21446C" },
    },
}, ptBR, corePtBR);

const EnterpriseTable = ({ updateSelectedTab, getClients }) => {
    
    const [selectedEnterprise, setSelectedEnterprise] = useState()
    const [showContactEnterpriseDialog, setShowContactEnterpriseDialog] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [showMassDeleteEnterpriseDialog, setShowMassDeleteEnterpriseDialog] = useState(false)
    const [selectedEnterprises, updateSelectedEnterprises] = useState([])
    const [enterprises, setEnterprises] = useState([])
    const [page, setPage] = useState(0)
    const [pageSize, setPageSize] = useState(25)
    const [rowCount, setRowCount] = useState(0)
    const [sortModel, setSortModel] = useState([])
    const [filterModel, setFilterModel] = useState((JSON.parse(localStorage.getItem('crmEnterpriseFilters'))) ? JSON.parse(localStorage.getItem('crmEnterpriseFilters')) : { items: [] })
    const [loading, setLoading] = useState(false)

    const deleteEnterprises = async (ids) => {
        const response = await api.post(`api/enterprises/${getSession().profile.enterprise.id}/contact_enterprises/bulk_delete/`, {
            ids: ids
        })
        if (response.status === 204) {
            setEnterprises((prevEnterprises) => prevEnterprises.filter(enterprise => ! ids.includes(enterprise.id)))
            setShowMassDeleteEnterpriseDialog(false)
            useNotification(
                "Sucesso!",
                "Empresas excluídas com sucesso.",
                "success"
            )
            return
        }

        useNotification(
            "Atenção!",
            "Uma ou mais empresas estão relacionadas com contatos, portanto não podem ser excluídas.",
            "warning"
        )
    }

    const deleteEnterprise = async (id) => {
        const response = await api.delete(`api/enterprises/${getSession().profile.enterprise.id}/contact_enterprises/${id}/`)
        if (response.status === 204) {
            setEnterprises((prevEnterprises) => prevEnterprises.filter(enterprise => enterprise.id !== id))
            setShowDeleteDialog(false)
            useNotification(
                "Sucesso!",
                "Empresa excluída com sucesso.",
                "success"
            )
            return
        }

        useNotification(
            "Atenção!",
            "Esta empresa está relacionada com um ou mais contatos, portanto não pode ser excluída.",
            "warning"
        )
    }

    const createEnterprise = async (data) => {
        data = Object.keys(data).reduce((acc, key) => {
            const snakeKey = key.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase()
            acc[snakeKey] = data[key]
            return acc
        }, {})
        const response = await api.post(`api/enterprises/${getSession().profile.enterprise.id}/contact_enterprises/`, data)

        if (response.status === 201) {
            setEnterprises((prevEnterprises) => [
                response.data,
                ...prevEnterprises
            ])
            setShowContactEnterpriseDialog(false)
            useNotification(
                "Sucesso!",
                `Empresa criada com sucesso.`,
                "success"
            )
            return
        }

        useNotification(
            "Ops!",
            `Algo deu errado, tente novamente.`,
            "danger"
        )
    }

    const updateEnterprise = async (data) => {
        data = Object.keys(data).reduce((acc, key) => {
            const snakeKey = key.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase()
            acc[snakeKey] = data[key]
            return acc
        }, {})
        const response = await api.patch(`api/enterprises/${getSession().profile.enterprise.id}/contact_enterprises/${data.id}/`, data)

        if (response.status === 200) {
            setEnterprises((prevEnterprises) =>
                prevEnterprises.map((enterprise) =>
                    enterprise.id === data.id ? { ...enterprise, ...response.data } : enterprise
                )
            )
            setShowContactEnterpriseDialog(false)
            useNotification(
                "Sucesso!",
                `Empresa atualizada com sucesso.`,
                "success"
            )
            return
        }

        if (response.status === 400) {
            useNotification(
                "Atenção!",
                "Já existe um empresa com esse nome.",
                "warning"
            )   
            return
        }

        useNotification(
            "Ops!",
            "Algo deu errado, tente novamente.",
            "danger"
        )
    }

    const columns = getEnterpriseColumns(setSelectedEnterprise, setShowDeleteDialog, setShowContactEnterpriseDialog, updateSelectedTab, getClients)

    const fetchEnterprises = async () => {
        const response = await api.get(`api/enterprises/${getSession().profile.enterprise.id}/contact_enterprises/paginated/`, {
            params: {
                page: page + 1,
                pageSize,
                sortField: sortModel.length ? sortModel[0].field : null,
                sortOrder: sortModel.length ? sortModel[0].sort : null,
                filters: JSON.stringify(filterModel.items),
            }
        })
        if (response.status === 200) {
            setRowCount(response.data.count)
            setEnterprises(response.data.results)
            return
        }

        useNotification(
            "Ops!",
            "Algo deu errado ao buscar as empresas.",
            "danger"
        )
    }

    useEffect(() => {
        fetchEnterprises()
    }, [page, pageSize, sortModel, filterModel])

    const table = useMemo(() => {

        return (
            <Box sx={{ width: "100%", height: "100%" }}>
                <ThemeProvider theme={theme}>
                    <DataGridPro
                        columns={columns}
                        rows={enterprises}
                        components={{ Toolbar: () => CustomToolbar(setSelectedEnterprise, setShowContactEnterpriseDialog, setShowMassDeleteEnterpriseDialog, selectedEnterprises) }}
                        density={"compact"}
                        checkboxSelection
                        disableSelectionOnClick
                        onSelectionModelChange={(newSelection) => updateSelectedEnterprises(newSelection)}
                        rowCount={rowCount}
                        pagination
                        paginationMode="server"
                        sortingMode="server"
                        filterMode="server"
                        page={page}
                        pageSize={pageSize}
                        onPageChange={(newPage) => setPage(newPage)}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        sortModel={sortModel}
                        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
                        filterModel={filterModel}
                        onFilterModelChange={(newFilterModel) => {
                            localStorage.setItem('crmEnterpriseFilters', JSON.stringify(newFilterModel))
                            setFilterModel(newFilterModel)
                        }}
                        getDetailPanelContent={(params) => (
                            <Box>
                                {(params.row.contacts && params.row.contacts.length > 0) ? (
                                    <List sx={{ display: 'flex', flexDirection: "row", flexWrap: "wrap", gap: 2 }}>
                                        {params.row.contacts.map((contact, index) => (
                                            <ListItem key={index} sx={{ 
                                                display: "flex", 
                                                flexDirection: "column", 
                                                alignItems: "flex-start", 
                                                gap: ".25rem",
                                                width: 'auto',
                                                minWidth: '17.5rem',
                                                flexShrink: 0
                                            }}>
                                                <Typography variant="body1">
                                                    {contact.name}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    <PhoneIcon sx={{ fontSize: "1rem", verticalAlign: "text-bottom" }}/> {contact.telephone}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    <EmailIcon sx={{ fontSize: "1rem", verticalAlign: "text-bottom" }}/> {contact.email}
                                                </Typography>
                                            </ListItem>
                                        ))}
                                    </List>
                                ) : (
                                    <Typography variant="body2" color="textSecondary" sx={{ padding: 2 }}>
                                        Sem contatos.
                                    </Typography>
                                )}
                            </Box>
                        )}
                    />
                </ThemeProvider>
            </Box>)
    }, [enterprises, selectedEnterprises])

    return (
        <>
            {table}
            {showContactEnterpriseDialog &&
                <ContactEnterpriseDialog
                    open={showContactEnterpriseDialog}
                    handleClose={() => setShowContactEnterpriseDialog()}
                    createEnterprise={createEnterprise}
                    updateEnterprise={updateEnterprise}
                    selectedEnterprise={selectedEnterprise}
                />
            }
            {showDeleteDialog &&
                <ConfirmationDialogCaptcha
                    open={showDeleteDialog}
                    title="Confirmação"
                    description={
                        <>
                            <Box>
                                Para excluir a empresa <span style={{ 'fontWeight': 'bold' }}>{selectedEnterprise.name}</span>, digite: <span style={{ 'fontWeight': 'bold' }}>excluir</span> no campo abaixo.
                            </Box>
                        </>
                    }
                    handleClose={() => setShowDeleteDialog(false)}
                    handleSubmit={() => deleteEnterprise(selectedEnterprise.id)}
                    captcha={'excluir'}
                />
            }
            {showMassDeleteEnterpriseDialog &&
                <ConfirmationDialogCaptcha
                    open={showMassDeleteEnterpriseDialog}
                    title="Confirmação"
                    description={
                        <>
                            <Box>
                                Para excluir as empresas selecionadas, digite: <span style={{ 'fontWeight': 'bold' }}>excluir</span> no campo abaixo.
                            </Box>
                        </>
                    }
                    handleClose={() => setShowMassDeleteEnterpriseDialog(false)}
                    handleSubmit={() => deleteEnterprises(selectedEnterprises)}
                    captcha={'excluir'}
                />
            }
        </>
    )
}

export default EnterpriseTable
