import React, { useState, useEffect } from "react";

import { Box, Grid, Divider, IconButton, Tooltip } from "@mui/material";
import { ListItem, List, ListItemText } from "@mui/material";
import FileIcon from '@mui/icons-material/DescriptionOutlined';
import PlayIcon from '@mui/icons-material/PlayCircle';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import PauseIcon from '@mui/icons-material/Pause';
import useNotification from "../../../Notification";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import PropTypes from "prop-types";

import Typography from "../../../Typography";
import AvatarName from "../../../AvatarName";
import ChangeField from "../../../ChangeField";

import api from '../../../../api';

import userPermissions from '../../../User/Permissions';
import { getSession } from '../../../../auth';

import "../styles.css";

export default function Profile({ agentCanUpdateContact, contactData, isManageCalls, updateField, fetchHistoryMessages, chat, reloadContact }) {

    const [groups, updateGroups] = useState({});
    const [isIntegration, updateIsIntegration] = useState(false);
    const [renderKey, updateRenderKey] = useState(0);
    const [render, updateRender] = useState(0.0);
    const [enterprises, setEnterprises] = useState([])

    const enterprise = getSession().profile.enterprise;

    const fetchContactEnterprises = () => {
        api.get(`api/enterprises/${getSession().profile.enterprise.id}/contact_enterprises/`)
            .then((res) => {
                if (res.status === 200)
                    setEnterprises(res.data)
            })
            .catch((err) => {})
    }

    useEffect(() => {
        updateGroups(userPermissions());
        fetchContactEnterprises()
    }, []);

    const disableEditInput = (field) => {
        const dataField = contactData ? contactData[field] : null;

        // If the field is empty or
        // the field is 'Usuário Anônimo' or
        // the settings allow the agent to edit or
        // is an operator
        if (!dataField ||
            dataField === 'Usuário Anônimo' ||
            agentCanUpdateContact || groups.isNotOnlyOperator
            // isNotOperator
        )
            return false;

        return true;
    }

    return (
        <Box>
            {contactData &&
                <Box className="contactHistory-box-2">
                    <Box className="avatar-profile">
                        {contactData.name 
                            ? <AvatarName name={contactData.name} size="big" />
                            : <AvatarName name="N" size="big" />
                        }
                    </Box>
                    <Box className="contactHistory-box-3">
                        <ChangeField value={contactData.name}
                            router={`api/enterprises/${contactData.enterprise}/contacts/${contactData.id}/`}
                            field="name"
                            label="Nome"
                            updateField={(data) => {
                                contactData.name = data;
                                if (chat){
                                    chat.contact = {...chat.contact, 'name': data}
                                }
                                updateField();
                            }}
                            disabledChange={disableEditInput('name')}
                        />
                    </Box>
                    <Box className="contactHistory-box-4" sx={{ display: "flex", flexDirection: "row" }}>
                        <ChangeField value={contactData.telephone}
                            router={`api/enterprises/${contactData.enterprise}/contacts/${contactData.id}/`}
                            field="telephone"
                            label="Telefone"
                            updateField={(data) => {
                                contactData.telephone = data;
                                if (chat){
                                    chat.contact = {...chat.contact, 'telephone': data}
                                }
                                updateField();
                            }}
                            disabledChange={isIntegration || disableEditInput('telephone')}
                            key={renderKey}
                            reloadContact={reloadContact}
                        />
                    </Box>
                    <Box className="contactHistory-box-4">
                        <ChangeField value={contactData.email}
                            router={`api/enterprises/${contactData.enterprise}/contacts/${contactData.id}/`}
                            field="email"
                            label="Email"
                            updateField={(data) => {
                                contactData.email = data;
                                if (chat){
                                    chat.contact = {...chat.contact, 'email': data}
                                }
                                updateField();
                            }}
                            disabledChange={isIntegration || disableEditInput('email')}
                            key={renderKey}
                        />
                    </Box>
                    <Box className="contactHistory-box-4">
                        <ChangeField value={contactData.customer_code}
                            router={`api/enterprises/${contactData.enterprise}/contacts/${contactData.id}/`}
                            field="customer_code"
                            label="Código"
                            updateField={(data) => {
                                contactData.customer_code = data;
                                if (chat){
                                    chat.contact = {...chat.contact, 'customer_code': data}
                                }
                                updateField();
                            }}
                            disabledChange={isIntegration || disableEditInput('customer_code')}
                            key={renderKey}
                        />
                    </Box>
                    <Box className="contactHistory-box-4">
                        <ChangeField value={contactData.contact_enterprise}
                            router={`api/enterprises/${contactData.enterprise}/contacts/${contactData.id}/`}
                            field="contact_enterprise"
                            label="Empresa"
                            updateField={(data) => {
                                contactData.contact_enterprise = data;
                                if (chat){
                                    chat.contact = {...chat.contact, 'contact_enterprise': data}
                                }
                                updateField();
                            }}
                            disabledChange={isIntegration || disableEditInput('contact_enterprise')}
                            key={renderKey}
                            contactEnterprises={enterprises}
                            fetchContactEnterprises={fetchContactEnterprises}
                        />
                    </Box>
                    <Box className="contactHistory-box-4" sx={{ marginBottom: "1.5rem !important" }}>
                        <ChangeField value={contactData.cpf}
                            router={`api/enterprises/${contactData.enterprise}/contacts/${contactData.id}/`}
                            field="cpf"
                            label="CPF"
                            updateField={(data) => {
                                contactData.cpf = data;
                                if (chat){
                                    chat.contact = {...chat.contact, 'cpf': data}
                                }
                                updateField();
                            }}
                            disabledChange={isIntegration || disableEditInput('cpf')}
                            key={renderKey}
                            cpfField={true}
                        />
                    </Box>
                    <span style={{ fontSize: "90%", fontStyle: "italic", margin: "0 0 1rem 0"}}>
                        Cadastrado em {new Date(contactData.created_at).toLocaleDateString('pt-BR')}
                    </span>
                </Box>
            }
        </Box>
    );
}
