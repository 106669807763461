import React, { useState, useContext, useEffect } from 'react'

import CryptoJS from 'crypto-js';

import { ListItem } from '@mui/material'

import ClientDialog from '../../../Dialogs/Client'
import ActionButton from '../../ActionButton'
import useNotification from '../../../Notification'
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs'

import api from '../../../../api'

import { ScreenContext } from '../..'
import { Box } from '@mui/material';

const AddClient = ({ groups, customFields, usersEmails }) => {
	const { updateClients, updateReady, clients, enterpriseId, settings, permissions, updateNumberSelectedModel } = useContext(ScreenContext)
	const [createClientModal, updateCreateClientModal] = useState(false)
	const [clientModel, updateClientModel] = useState([])

	const isNumber = (n) => {
		return !isNaN(parseFloat(n)) && isFinite(n);
	}

	const formatPhone = (telephone) => {
		let newTelephone = '';
		if (telephone) {
			const numberTelephone = telephone.replace(/[^0-9]+/g, '');
			if (numberTelephone.length < 10)
				return false
			if (isNumber(numberTelephone) && numberTelephone.substring(0, 3) !== '+55')
				newTelephone = `+55${numberTelephone}`;
			else
				newTelephone = telephone;
		}
		return newTelephone;
	}

	const generateUniqueNick = async () => {
		const randomString = Math.random().toString(36).substring(2)
		return CryptoJS.MD5(randomString).toString()
	}

	const handleCreateClient = async (name, email, telephone, userFields, advisor, contactEnterprise, cpf, customer_code, groups, toCreateCustomFields, notificationOptOut, owner) => {

		const formatTelephone = formatPhone(telephone);

		if (! name && ! email && ! telephone) {
			useNotification(
                'Ops!',
                `Insira pelo menos um: nome, email, ou telefone.`,
                'warning'
            )
			return;
		}

		updateReady(false)
		updateNumberSelectedModel([])

		const payload = {
			// Setting default nick as telephone for WhatsApp notifications
			nick                 : (telephone) ? formatTelephone : (email) ? email : await generateUniqueNick(),
			name                 : name,
			email                : email,
			telephone            : formatTelephone,
			user_fields          : userFields,
			advisor              : advisor,
			contact_enterprise   : contactEnterprise,
			cpf                  : cpf,
			customer_code        : customer_code,
			notification_opt_out : notificationOptOut,
			groups               : groups,
			owner                : owner === '' ? null : owner
		}

		if (toCreateCustomFields.length !== 0)
			payload['create_custom_fields'] = toCreateCustomFields

		api.post(`/api/enterprises/${enterpriseId}/contacts/`, payload)
			.then(async (res) => {
				updateReady(true)

				if (res.status == 201) {

					const client = res.data

					updateClients(prevState => [...prevState, client].sort((x, y) => x.id > y.id ? -1 : 1));
					updateCreateClientModal(false)

					useNotification(
						'Sucesso!',
						'Cliente adicionado',
						'success'
					);

					return;
				}
				throw res;
			})
			.catch(async (err) => {
				updateReady(true)
				updateCreateClientModal(false)
				if (err.data.details?.email) {

					// Validates duplicated email adress
					if (err.data.details?.email[0].error === 'DuplicateValue') {
						useNotification(
							'Ops!',
							`O e-mail "${email}" já está cadastrado na base do zendesk em outro contato`,
							'danger'
						);

						return;
					}



					// Validates any other error
					useNotification(
						'Ops!',
						err.data.details?.email[0].description,
						'danger'
					);

					return;
				}

				let defaultError = 'Ocorreu um erro no sistema'
				if (err.data?.error && err.data?.contact) 
					defaultError = `Este contato já existe - pertence a ${err.data.contact.owner_name} criado em ${dayjs(err.data.contact.created_at).format('DD/MM/YYYY')}. `
				else if (err.status == 400) {
		            const errorMessages = {
		                'Invalid data type. Expected an integer.'    : 'deve conter um número.',
		                'Invalid data type. Expected either 0 or 1.' : 'deve conter Sim ou Não.',
		            };
		            Object.keys(err.data).forEach((key) => {
		                err.data[key].forEach((error) => {
		                    if (errorMessages[error])
		                        defaultError = `O campo ${key} ${errorMessages[error]}`;
		                });
		            })
		        }

				if (err.status === 406) {
					defaultError = 'Limite de contatos atingido. Contate o seu gerente comercial.';
				} else if (err.status === 405)
					defaultError = 'Você não pode criar contatos.';

				// Fallback for unknown errors
				useNotification(
					'Ops!',
					defaultError,
					'danger'
				);
				console.error(err);
			});
	}

	return (
		<>
			<ListItem className={'menuItem'} sx={{ padding: 0 }} >
				{createClientModal &&
					<ClientDialog open={true}
						title="Novo cliente"
						type="create"
						model={clientModel}
						key={'ClientCreate'}
						buttonText="Adicionar"
						handleClose={() => updateCreateClientModal(false)}
						submit={handleCreateClient}
						is_financial_market_company={settings.is_financial_market_company}
						isAdmin={permissions.isAdmin}
						listOfUsers={usersEmails}
						groups={groups}
						customFields={customFields}
					/>}
				<Box sx={{ width: "100%", padding: ".5rem", marginRight: "1.5rem" }} onClick={() => updateCreateClientModal(true)}>Novo contato</Box>
			</ListItem>
		</>
	)
}

export default AddClient