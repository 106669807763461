import React, { useState, useEffect, useContext, useReducer } from 'react'

import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    gridClasses,
    LicenseInfo,
    ptBR
} from '@mui/x-data-grid-pro';

import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider
} from '@mui/material/styles';

import { ptBR as corePtBR } from '@mui/material/locale';

import { Box, Tooltip, Button } from '@mui/material';


import EditIcon from '@mui/icons-material/Edit';
import useNotification from '../../Notification';
import DeleteIcon from '@mui/icons-material/Delete';

import api from '../../../api';
import ConfirmationDialogCaptcha from '../../Dialogs/ConfimationDialogCaptcha';

import LoadingButton from '@mui/lab/LoadingButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RedirectEditModal from './RedirectEditModal';
import Loading from '../../Loading';
import { getSession } from '../../../auth';
import defaultReducer from '../../../utils/defaultReducer';
import { 
    stringOperators,
    choiceOperators 
} from '../../../utils/filterOperators';
import { replaceMCDU } from '../Vivre/Vivremenu/replaceMCDU';

const theme = createTheme({
    palette: {
        primary: { main: '#21446C' },
    },
}, ptBR, corePtBR);

const Redirect = () => {

    const enterpriseId = getSession()?.profile?.enterprise?.id

    const [rows, setRows] = useState([])
    const [mcdu, setMcdu] = useState([])
    const [loading, setLoading] = useState(false)
    const [deleteModal, setDeleteModal] = useState()
    const [editModal, setEditModal] = useState()

    const [channels, setChannels] = useReducer(defaultReducer, {
        whatsapp: []
    })

    const getURL = () => {
        let url = `api/enterprises/${enterpriseId}/redirect/`
        return url
    }

    const getRows = async () => {
        setLoading(true)
        const res = await api.get(getURL(), { timeout: 0 })
        setLoading(false)
        if (res.status == 200) {
            setRows(res.data)
            return
        }
    }

    const getMcdus = async () => {
        let url = `api/enterprises/${enterpriseId}/mcdu/chat/`
        const res = await api.get(url, { timeout: 0 })
        if (res.status == 200) {
            setMcdu(res.data)
            return
        }

        useNotification(
            'Ops!',
            'Não foi possível carregar as uras.',
            'danger'
        )

    }

    const getWhatsappChannels = async () => {
        const dialog360 = await api.get(`api/enterprises/${enterpriseId}/360dialog_channels/`, { timeout: 0 })
        const meta = await api.get(`api/enterprises/${enterpriseId}/whatsapp/`, { timeout: 0 })

        if (dialog360.status == 200 && meta.status == 200) {
            setChannels({ whatsapp: [...dialog360.data, ...meta.data] })
            return
        }

        useNotification(
            'Ops!',
            'Não foi possível carregar os números de whatsapp.',
            'danger'
        )

    }

    const getChannels = () => {
        getWhatsappChannels()
    }

    useEffect(() => {
        getRows()
        getMcdus()
        getChannels()
    }, [])

    const submitDelete = async () => {
        let url = `api/enterprises/${enterpriseId}/redirect/${deleteModal.id}/`

        setLoading(true)
        const res = await api.delete(url, { timeout: 0 })
        setLoading(false)

        if (res.status == 204) {

            setDeleteModal()
            getRows()

            useNotification(
                'Sucesso!',
                'Redirecionamento deletado com sucesso.',
                'success'
            )
            return
        }
        useNotification(
            'Ops!',
            'Não foi possível excluir o redirecionamento.',
            'danger'
        )
    }


    const CustomToolbar = () => {

        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <LoadingButton
                    size="small"
                    component="label"
                    startIcon={<AddCircleIcon />}
                    onClick={() => { setEditModal({}) }}
                >
                    Adicionar
                </LoadingButton>
            </GridToolbarContainer>
        );
    }

    const defColumns = [
        { field: 'user_id', headerName: 'Número do cliente', flex: 1, filterOperators: stringOperators },
        { field: 'username', headerName: 'Nome do cliente', flex: 1, filterOperators: stringOperators },
        { field: 'entrypoint', headerName: 'Número do destino', flex: 1, filterOperators: stringOperators },
        {
            field: 'channel', headerName: 'Canal', flex: 0.3, filterable: false, renderCell: (params) => {
                const entrypoint = params?.row?.mcdu
                const selectedMcdu = mcdu.find(each => each.mcdu === entrypoint)
                if (selectedMcdu)
                    return (
                        <>
                            <Tooltip title={selectedMcdu.entrypoint.toLocaleLowerCase()}>
                                <Box>{replaceMCDU(selectedMcdu.entrypoint)}</Box>
                            </Tooltip>
                        </>
                    )
                return <></>
            }
        },
        { field: 'mcdu', headerName: 'Fluxo', flex: 1, filterOperators: stringOperators },
        {
            field: 'action', headerName: 'Ações', filterable: false, sortable: false,
            renderCell: (params) => (
                <>
                    <Tooltip title="Editar" >
                        <Button
                            className="action-button"
                            onClick={() => { setEditModal(params.row) }}
                        >
                            <EditIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Excluir">
                        <Button className="action-button delete"
                            onClick={() => { setDeleteModal(params.row) }}>
                            <DeleteIcon />
                        </Button>
                    </Tooltip>
                </>
            ),
        },
    ]

    return (
        <>

            {editModal ?
                <RedirectEditModal
                    data={editModal}
                    setData={setEditModal}
                    getRows={getRows}
                    mcdu={mcdu}
                    channels={channels}
                    setLoading={setLoading}
                />
                :
                null}
            {deleteModal ?
                <ConfirmationDialogCaptcha open={true}
                    title="Excluir"
                    description={
                        <span>
                            Para confirmar a ação, digite: <span style={{ 'fontWeight': 'bold' }}>{deleteModal.user_id}</span> no campo abaixo.</span>}
                    handleClose={() => { setDeleteModal() }}
                    handleSubmit={() => { submitDelete() }}
                    captcha={deleteModal.user_id}
                />

                :
                null
            }
            <Loading loading={loading} />

            <Box className="settings-body-bot">
                <StyledEngineProvider injectFirst >
                    <ThemeProvider theme={theme}>
                        <DataGridPro
                            pageSize={100}
                            pagination
                            disableSelectionOnClick
                            disableDensitySelector
                            density="compact"
                            columns={defColumns}
                            rows={rows}
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                        />
                    </ThemeProvider>
                </StyledEngineProvider>
            </Box>

        </>
    )
}

export default Redirect