import React from 'react';

import { IMaskInput } from 'react-imask';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    MenuItem,
    FormControl,
    Divider,
    Tooltip,
    TextField,
    Select,
    InputLabel,
    FormHelperText,
    Box,
    Autocomplete,
    CircularProgress,
    Chip,
    IconButton
} from '@mui/material';

import LoadingButton from '@mui/lab/LoadingButton';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from "@mui/icons-material/Add"

import MultipleSelections from '../../Input/MultipleSelections';
import UserPermissions from '../../User/Permissions';

import api from '../../../api';
import { getSession } from '../../../auth';

import useNotification from "../../Notification"

import './index.css';

let timeoutId

class ClientDialog extends React.Component {

    constructor(props) {
        super(props);

        this.queues = {};

        this.state = {
            name: "",
            email: "",
            initialEmail: "",
            telephone: "",
            initialTelephone: "",
            customer_code: "",
            enterprise: "",
            cpf: "",
            userFields: {},
            advisor: "",
            customFields: [],
            toCreateCustomFields: [],
            notificationOptOut: false,
            groupsSelectOpen: false,
            groupsloading: false,
            contactGroups: [],
            selectedGroups: [],
            owner: getSession().id,
            enterprises: [],
            showContactEnterpriseDialog: false,
            newEntepriseName: ""
        }
    }

    componentWillMount() {
        api.get(`api/enterprises/${getSession().profile.enterprise.id}/contact_enterprises/`).then((res) => {
            if (res.status === 200)
                this.setState({ enterprises: res.data })
        }).catch((err) => {})
    }

    componentDidMount() {
        if (this.props.type === 'create')
            this.createClient();
        else
            this.changeClient();
    }

    createClient = () => {
        // Handle Zendesk user fields
        let userFields = this.props.model ? this.props.model.user_fields : {};

        if (userFields) {
            Object.keys(userFields).forEach((key) => {
                userFields[key] = "";
            });
        } else { userFields = {}; }

        this.setState({
            userFields: userFields,
            ...this.props.model
        });
    }

    changeClient = () => {
        let userFields = this.props.model ? this.props.model.user_fields : {};

        if (!userFields) userFields = {} // Avoid undefined render error

        let name = '';
        let email = '';
        let telephone = '';
        let initialTelephone = '';
        let customer_code = '';
        let enterprise = '';
        let cpf = '';
        let advisor = '';
        let customFields = [];
        let notificationOptOut = false;
        let owner = '';

        if (this.props.model) {
            name = this.props.model.name;
            email = this.props.model.email;
            customer_code = this.props.model.customer_code;
            telephone = this.props.model.telephone;
            enterprise = this.props.model.contact_enterprise || "";
            cpf = this.props.model.cpf;
            advisor = this.props.model.advisor;
            notificationOptOut = this.props.model.notification_opt_out;
            owner = this.props.model.owner

            if (this.props.model.custom_fields)
                customFields = JSON.parse(JSON.stringify(this.props.model.custom_fields))
        };

        this.setState({
            name: name,
            email: email,
            initialEmail: email,
            telephone: telephone,
            initialTelephone: telephone,
            customer_code: customer_code,
            notificationOptOut: notificationOptOut,
            enterprise: enterprise,
            cpf: cpf,
            userFields: userFields,
            advisor: advisor,
            customFields: customFields,
            selectedGroups: [...this.props.model.groups],
            owner: owner
        });
    }

    setUserField = (key, value) => {
        const userFields = this.state.userFields;

        userFields[key] = value;

        this.setState({
            userFields: userFields
        });
    }

    submit = () => {
        let telephone = this.state.initialTelephone;
        let email = this.state.initialEmail;
        let advisor = this.state.advisor;

        if (!this.disabledInput('initialTelephone'))
            telephone = this.state.telephone;

        if (!this.disabledInput('initialEmail'))
            email = this.state.email;

        if (this.props.type === 'create') {
            this.props.submit(
                this.state.name,
                email,
                telephone,
                this.state.userFields,
                advisor,
                this.state.enterprise,
                this.state.cpf,
                this.state.customer_code,
                this.state.selectedGroups.map((group) => group.id),
                this.state.toCreateCustomFields,
                this.state.notificationOptOut,
                this.state.owner
            );
        } else {
            this.props.submit(
                this.props.model ? this.props.model.id : null,
                this.state.name,
                this.state.initialEmail,
                email,
                this.state.initialTelephone,
                telephone,
                this.state.userFields,
                advisor,
                this.state.enterprise,
                this.state.cpf,
                this.state.customer_code,
                this.state.selectedGroups.map((group) => group.id),
                this.state.customFields,
                this.state.toCreateCustomFields,
                this.state.notificationOptOut,
                this.state.owner
            );
        }
    }

    capitalize = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    telephoneMask = React.forwardRef(function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask={[
                    {
                        mask: '(00) 0000-0000',
                        overwrite: true,
                    }, {
                        mask: '(00) 00000-0000',
                        overwrite: true,
                    },
                ]}
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    });

    cnpjMask = React.forwardRef(function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="000.000.000-00"
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    });

    telephoneMaskComponent = () => {
        if (this.props.crm_integrated && this.state.initialTelephone)
            return null;

        return this.telephoneMask;
    }

    cnpjMaskComponent = () => {
        return this.cnpjMask;
    }

    disabledInput = (initialStateName) => {
        return this.props.crm_integrated && this.state[initialStateName];
    }

    infoInputBlocked = () => {
        return (
            <FormHelperText id="component-helper-text" className="clients-helper-text">
                Só é possível alterar o valor acima pelo CRM.
            </FormHelperText>
        );
    }

    getCustomValue = (field) => {
        if (this.state.customFields.length === 0 && this.state.toCreateCustomFields === 0)
            return ''

        const customField = this.state.customFields.find((row) => row.custom_field.id === field.id)
        if (customField)
            return customField.value

        const toCreateCustomField = this.state.toCreateCustomFields.find((row) => row.custom_field.field_name === field.field_name)
        if (toCreateCustomField)
            return toCreateCustomField.value

        return ''
    }

    updateCustomField = (field, value) => {
        const customFields = this.state.customFields
        const toCreateCustomFields = this.state.toCreateCustomFields

        let found = false
        for (const customField of customFields) {
            if (customField.custom_field.id === field.id) {
                found = true
                customField.value = value
            }
        }

        for (const toCreateCustomField of toCreateCustomFields) {
            if (toCreateCustomField.custom_field.field_name === field.field_name) {
                found = true
                toCreateCustomField.value = value
            }
        }

        const newCustomFields = []
        if (!found) {
            // We need to create a list of custom fields to add
            newCustomFields.push({
                custom_field: field,
                value: value
            })
        }

        this.setState({
            customFields: customFields,
            toCreateCustomFields: [...toCreateCustomFields, ...newCustomFields]
        })
    }

    renderCustomField = (field) => {
        switch (field.field_type) {
            case 'string':
                return (
                    <TextField
                        size="small"
                        label={field.field_name}
                        value={this.getCustomValue(field)}
                        fullWidth
                        type="text"
                        variant="outlined"
                        className="push-top"
                        onChange={(event) => this.updateCustomField(field, event.target.value)}
                    />
                )
                break;
            case 'integer':
                return (
                    <TextField
                        size="small"
                        label={field.field_name}
                        value={this.getCustomValue(field)}
                        fullWidth
                        type="number"
                        variant="outlined"
                        className="push-top"
                        onChange={(event) => this.updateCustomField(field, event.target.value)}
                    />
                )
                break;
            case 'boolean':
                return (
                    <FormControl fullWidth size="small" className="push-top">
                        <InputLabel id="custom-field-type-select">{field.field_name}</InputLabel>
                        <Select
                            labelId="custom-field-type-select"
                            value={this.getCustomValue(field)}
                            label={field.field_name}
                            onChange={(event) => this.updateCustomField(field, event.target.value)}
                        >
                            <MenuItem value=""></MenuItem>
                            <MenuItem value="1">Sim</MenuItem>
                            <MenuItem value="0">Não</MenuItem>
                        </Select>
                    </FormControl>
                )
                break;
        }
    }

    getGroups = (search, groupsUrl) => {
        if (groupsUrl === undefined || (typeof search !== 'object' && search !== undefined)) {
            // Reset contactGroups
            this.setState({
                groupsSelectOpen: true,
                groupsloading: true,
                contactGroups: []
            })
        } else {
            this.setState({
                groupsSelectOpen: true,
                groupsloading: true
            })
        }

        const enterpriseId = getSession().profile.enterprise.id

        let url
        if (groupsUrl !== undefined)
            url = `/api/${groupsUrl.split('/api/')[1]}`
        else {
            url = `/api/enterprises/${enterpriseId}/contact_group/noserialize/`
            if (typeof search !== 'object' && search !== undefined)
                url = `${url}?search=${search}`
        }

        api.get(url).then((res) => {
            if (res.status === 200)
                this.setState((prevState) => ({
                    groupsloading: false,
                    nextGroupsPointer: res.data.next,
                    contactGroups: [...prevState.contactGroups, ...res.data.results]
                }))
        }).catch((err) => { })
    }

    handleClose = () => {
        this.setState({ groupsSelectOpen: false })
    }

    handleScroll = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        if (this.state.nextGroupsPointer && scrollHeight - scrollTop <= clientHeight + 5)
            this.getGroups(undefined, this.state.nextGroupsPointer)
    }

    canChangeOwner = (owner) => {
        const permissions = UserPermissions()
        if (permissions.isAdmin || permissions.isSupervisor) return true
        if (owner === null) return false
        return true
    }

    canRemoveContactGroupRelation = async (group) => {
        const enterpriseId = getSession().profile.enterprise.id
        const response = await api.post(`/api/enterprises/${enterpriseId}/contact_group/can_remove_relation/`, {
            group_id: group.id
        })
        return response.status === 200
    }

    createNewEnterprise = () => {
        const response = api.post(`api/enterprises/${getSession().profile.enterprise.id}/contact_enterprises/`, {
            name: this.state.newEnterpriseName
        }).then((res) => {
            if (res.status === 201) {
                this.setState(prevState => ({
                    enterprises: [...prevState.enterprises, res.data]
                }), () => {
                    this.setState({ 
                        enterprise: res.data.id, 
                        showContactEnterpriseDialog: false 
                    });
                })
                return
            }
            if (res.status === 400) {
                useNotification(
                    "Atenção!", 
                    "Já existe uma empresa com esse nome.", 
                    "warning"
                )
                return
            }
        }).catch((err) => {})
    }

    render() {
        return (
            <div>
                <Dialog open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="customized-dialog-title"
                    fullWidth
                    disableEnforceFocus
                >
                    <DialogTitle id="customized-dialog-title" className={'group-flexbox'}>
                        <span>{this.props.title}</span>
                        <Button sx={{ 'minWidth': '2rem', 'padding': '0' }} onClick={this.props.handleClose} >
                            <CloseIcon />
                        </Button>
                    </DialogTitle>
                    <Divider />
                    <DialogContent
                        ref={instance => { this.content = instance; }}>
                        <TextField
                            size="small"
                            label="Nome"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={this.state.name}
                            onChange={(event) => this.setState({
                                name: event.target.value
                            })}
                        />
                        <TextField
                            size="small"
                            label="Email"
                            fullWidth
                            type="text"
                            variant="outlined"
                            className="push-top"
                            value={this.state.email}
                            onChange={(event) => this.setState({
                                email: event.target.value
                            })}
                            disabled={this.disabledInput('initialEmail')}
                        />
                        {this.disabledInput('initialEmail') && this.infoInputBlocked()}
                        <Tooltip title='Obrigatório'>
                            <TextField
                                size="small"
                                label="Telefone"
                                fullWidth
                                type="text"
                                variant="outlined"
                                className="push-top"
                                value={this.state.telephone ? this.state.telephone.replace('+55', '') : null}
                                onChange={(event) => this.setState({
                                    telephone: event.target.value
                                })}
                                InputProps={{
                                    inputComponent: this.telephoneMaskComponent()
                                }}
                                disabled={this.disabledInput('initialTelephone')}
                            />
                        </Tooltip>
                        {this.disabledInput('initialTelephone') && this.infoInputBlocked()}
                        <TextField
                            size="small"
                            label="Código"
                            fullWidth
                            type="text"
                            variant="outlined"
                            className="push-top"
                            value={this.state.customer_code}
                            onChange={(event) => this.setState({
                                customer_code: event.target.value
                            })}
                        />
                        <Box display="flex" alignItems="center" gap={1} className="push-top">
                            <FormControl fullWidth size="small">
                                <Autocomplete
                                    options={this.state.enterprises}
                                    getOptionLabel={(option) => option.name || ""}
                                    value={this.state.enterprises.find(e => e.id === this.state.enterprise) || null}
                                    onChange={(event, newValue) => this.setState({ enterprise: newValue ? newValue.id : "" })}
                                    renderInput={(params) => <TextField {...params} label="Empresa" size="small"/>}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                />
                            </FormControl>
                            <IconButton size="small" onClick={() => this.setState({ showContactEnterpriseDialog: true })}>
                                <AddIcon />
                            </IconButton>
                        </Box>
                        <TextField
                            size="small"
                            label="CPF"
                            fullWidth
                            type="text"
                            variant="outlined"
                            className="push-top"
                            value={this.state.cpf}
                            onChange={(event) => this.setState({
                                cpf: event.target.value
                            })}
                            InputProps={{
                                inputComponent: this.cnpjMaskComponent()
                            }}
                        />
                        {this.canChangeOwner(this.state.owner) && <FormControl fullWidth size="small" className="push-top">
                            <InputLabel id="notification-opt-out-label">Dono</InputLabel>
                            <Select
                                labelId="notification-opt-out-label"
                                value={this.state.owner}
                                label="Dono"
                                onChange={(event) => this.setState({ owner: event.target.value })}
                            >
                                <MenuItem id='user-item-none' value={''}>Público</MenuItem>
                                {this.props.listOfUsers.map((item) => (
                                        <MenuItem id={`user-item-${item.id}`} value={item.id}>{item.first_name} {item.last_name} - {item.username}</MenuItem>
                                    ))}
                            </Select>
                        </FormControl>}
                        <FormControl fullWidth size="small" className="push-top">
                            <InputLabel id="notification-opt-out-label">Recebe campanhas</InputLabel>
                            <Select
                                labelId="notification-opt-out-label"
                                value={!this.state.notificationOptOut}
                                label="Recebe campanhas"
                                onChange={(event) => this.setState({ notificationOptOut: !event.target.value })}
                            >
                                <MenuItem value={true}>Sim</MenuItem>
                                <MenuItem value={false}>Não</MenuItem>
                            </Select>
                        </FormControl>
                        <>
                            <Box className="category-title push-top">Segmentos</Box>
                            <Autocomplete
                                multiple
                                sx={{ marginTop: "1rem", marginBottom: "1rem" }}
                                size="small"
                                open={this.state.groupsSelectOpen}
                                onOpen={this.getGroups}
                                onClose={this.handleClose}
                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                getOptionLabel={(option) => option.name}
                                options={this.state.contactGroups}
                                loading={this.state.groupsloading}
                                filterOptions={(x) => x}
                                onInputChange={(event) => {
                                    if (timeoutId)
                                        clearTimeout(timeoutId)
                                    timeoutId = setTimeout(() => this.getGroups(event.target.value), 1000)
                                }}
                                value={this.state.selectedGroups}
                                // onChange={(event, newValue) => this.setState({ selectedGroups: newValue })}
                                onChange={async (event, newValue) => {
                                    const removedItem = this.state.selectedGroups.find((item) => !newValue.some((newItem) => newItem.name === item.name))
                                    if (removedItem) {
                                        const canRemove = await this.canRemoveContactGroupRelation(removedItem)
                                        if (canRemove)
                                            this.setState({ selectedGroups: newValue })
                                        else
                                            useNotification(
                                                "Atenção!", 
                                                "Você não tem permissão para remover este segmento.", 
                                                "warning"
                                            )
                                    } else 
                                        this.setState({ selectedGroups: newValue })
                                }}
                                ListboxProps={{ onScroll: this.handleScroll }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Segmentos"
                                        slotProps={{
                                            input: {
                                                ...params.InputProps,
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {this.state.groupsloading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),
                                            },
                                        }}
                                    />
                                )}
                            />
                        </>
                        {this.props.customFields && this.props.customFields.length !== 0 && (
                            <>
                                <Box className="category-title">Campos customizados</Box>
                                {this.props.customFields.map((field) => this.renderCustomField(field))}
                            </>
                        )}
                        {Object.keys(this.state.userFields).map((key, index) => (
                            <TextField
                                size="small"
                                label={this.capitalize(key.replaceAll('_', ' '))}
                                fullWidth
                                type="text"
                                variant="outlined"
                                className="push-top"
                                value={this.state.userFields[key]}
                                onChange={(event) => this.setUserField(
                                    key,
                                    event.target.value
                                )}
                            />
                        ))}
                        {this.props.is_financial_market_company && this.props.isAdmin &&
                            <FormControl
                                fullWidth
                                size="small"
                                className="push-top"
                            >
                                <InputLabel id="select-label-advisor-label">Assessor</InputLabel>
                                <Select
                                    labelId="select-label-advisor-label"
                                    size="small"
                                    variant="outlined"
                                    label="Assessor"
                                    value={this.state.advisor}
                                    fullWidth
                                    onChange={(event) => { this.setState({ advisor: event.target.value }) }}
                                >
                                    {this.props.listOfUsers.map((item) => (
                                        <MenuItem id={item} value={item}>{item}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        }
                    </DialogContent>
                    <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                        <LoadingButton
                            onClick={this.props.handleClose}
                            color="primary"
                            loading={this.props.submitButtonLoading}
                        >
                            Cancelar
                        </LoadingButton>
                        <LoadingButton
                            onClick={() => this.submit()}
                            color="primary"
                            loading={this.props.submitButtonLoading}
                        >
                            {this.props.buttonText}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
                {this.state.showContactEnterpriseDialog &&
                    <Dialog 
                        open={this.state.showContactEnterpriseDialog} 
                        onClose={() => this.setState({ showContactEnterpriseDialog: false })} 
                        fullWidth
                        maxWidth="xs"
                    >
                        <DialogTitle>Nova empresa</DialogTitle>
                        <Divider />
                        <DialogContent>
                            <TextField
                                size="small"
                                fullWidth
                                label="Nome"
                                value={this.state.newEnterpriseName}
                                onChange={(event) => this.setState({ newEnterpriseName: event.target.value })}
                            />
                        </DialogContent>
                        <DialogActions style={{ padding: "10px 20px 10px 10px" }}>
                            <LoadingButton onClick={() => this.setState({ showContactEnterpriseDialog: false })} color="primary">
                                Cancelar
                            </LoadingButton>
                            <LoadingButton onClick={() => this.createNewEnterprise()} color="primary" disabled={! this.state.newEnterpriseName}>
                                Salvar
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>
                }
            </div>
        );
    }
}

export default ClientDialog;
