import React, { useState, useEffect } from "react";

import { Box, InputAdornment, TextField, Divider, List } from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";

import { ListItem, ListItemButton } from '@mui/material';

import GroupAutocomplete from '../../../Autocomplete/Group'

import '../styles.css';
import { getSession } from "../../../../auth";
import api from "../../../../api";
import useNotification from "../../../Notification";


export default function GroupArea({ chat, chatGroups, allGroups, setChatGroups }) {

    const [filterValue, setFilterValue] = useState('')
    const [groupList, updateGroupList] = useState([])
    const [groupListFiltered, updateGroupListFiltered] = useState([])
    const [refresh, setRefresh] = useState(false)

    const reloadState = () => {setRefresh(p=>!p)}

    useEffect(()=>{
        if (filterValue != '')
            handleFilterChange(filterValue)
    }, [groupList])
    
    useEffect(() => {
        const newGroups = initializeGroupList()
        updateGroupList(newGroups)
        if (filterValue == '')
            updateGroupListFiltered(newGroups)
                    
    }, [refresh])

    const initializeGroupList = () => {
        const chatGroupsIds = chatGroups.map((chatGroup) => chatGroup.id)
        return allGroups.filter(group => !chatGroupsIds.includes(group.id))
    }

    const subsObj = (objOld, objNew) => {
        const parsed = {}
        objOld.map(each => { parsed[each.id] = each })
        const newobj = {}
        if (Array.isArray(objNew)) {
            objNew.map(each => { newobj[each.id] = each })
            return Object.values({ ...parsed, ...newobj })
        }
        newobj[objNew.id] = objNew
        return Object.values({ ...parsed, ...newobj })
    }

    const moveGroup = async (group, command) => {
        if (! group) return
        const enterpriseId = getSession().profile.enterprise.id
        const url = `api/enterprises/${enterpriseId}/contact_group_contacts/`
        const contact_id = ('lhc' in chat) ? chat.lhc.contact.id : chat.contact.id
        if (command === 'add') {
            const payload = { group: group.id, contact: chat.contact.id }
            const response = await api.post(url, payload)
            if (response.status === 201) {
                updateGroupListFiltered(groupListFiltered.filter((each) => each.id != group.id))
                chatGroups.push(response.data)
                chat.contact.groups = chatGroups
                setChatGroups(chat)
                useNotification(
                    'Sucesso!',
                    'Segmento adicionado com sucesso.',
                    'success'
                );
            }
        }

        if (command === 'remove') {
            const payload = { group: group.id, contact: chat.contact.id }
            const response = await api.post(`${url}/delete_relation/`, payload)
            if (response.status === 204) {
                groupListFiltered.push(group)
                updateGroupListFiltered(groupListFiltered)
                chat.contact.groups = chatGroups.filter((each) => each.id != group.id)
                setChatGroups(chat)
                useNotification(
                    'Sucesso!',
                    'Segmento removido com sucesso.',
                    'success'
                );
            }
            if (response.status === 403) {
                useNotification(
                    'Atenção!',
                    'Você não tem permissão para remover este segmento.',
                    'warning'
                );
            }
        }
    }

    const handleFilterChange = (value) => {
        setFilterValue(value)
        const newgrouplist = groupList.filter((group) => {
            if (group.name && value)
                return group.name.toLowerCase().includes(value.toLowerCase())
            else if (value === "")
                return true
        })
        updateGroupListFiltered(newgrouplist)
    }

    const renderChatGroup = (group, command) => {
        return (
            <ListItem
                disableGutters
                className="label-item"
                onClick={() => {moveGroup(group, command)}}
                style={{ backgroundColor: "#757575" }}
            >
                <ListItemButton  disableGutters sx={{ padding: "4px", overflow: "hidden" }}>
                    <span>{group.name}</span>
                </ListItemButton>
            </ ListItem >
        )
    }

    const renderChatGroups = () => {
        return (
            <>
                {chatGroups.length == 0 ?
                    <Box key={'empty-list-nice-key'} sx={{ margin : "1rem" }}> Cliente não possui segmentos. </Box>
                    :
                    <List className='label-list'>
                        {chatGroups.map(each=>renderChatGroup(each, 'remove'))}
                    </List>
                }
            </>
        )
    }
    
    return (
        <>
            {renderChatGroups()}
            <Divider style={{ marginBottom: 0 }} className="contactHistory-divider" />
            <GroupAutocomplete allGroups={allGroups} onChange={(group) => moveGroup(group, 'add')} />
        </>
    )
}
