import React from 'react'
import SettingsIntermediate from '../SettingsIntermediate'
import userPermissions from '../../User/Permissions';
import { getSession } from '../../../auth';
import Integrations from '../../Settings/Integrations';
import ExternalIntegrations from '../../Settings/Integrations/external';
import KwikApiKey from '../../Settings/Integrations/kwik-api-key';
import { Box, Alert } from '@mui/material';
import Settings from '../../Scout/Pages/settings-page'
import AdvancedConfigs from '../../Scout/Pages/advancedconfs-page';
import RestrictedWordsComponent from '../../Settings/RestrictedWords';

const ScoutPage = () => {
    const groups = userPermissions();
    const enterprise = getSession()?.profile?.enterprise
    return (
        <>
            {enterprise.platforms.scout ? <SettingsIntermediate title='Scout' components={() => ({
                comp1: {
                    permissions: true,
                    style: 'title',
                    label: 'Scout'
                },
                comp2: {
                    permissions: groups.isAdmin,
                    style: 'list',
                    label: 'Gestão de telefones',
                    sublabel: '',
                    redirect: (<Settings />),
                    isVisible: true
                },
                comp3: {
                    permissions: groups.isAdmin,
                    style: 'list',
                    label: 'Configurações avançadas',
                    sublabel: '',
                    redirect: (<AdvancedConfigs />)
                },
                comp4: {
                    permissions: groups.isAdmin,
                    style: 'list',
                    label: 'Palavras restritas',
                    sublabel: '',
                    redirect: (<RestrictedWordsComponent enterpriseId={enterprise?.id} scout={1}/>)
                }
            })
            } />
                :
                <Box className='scout-alert-padding'>
                    <Alert severity="info">
                        Entre em contato com seu gerente de contas para contratar o Kwik Scout - O canal de "Compliance"  e gravacao de WhatsApp do Kwik - <a href="https://wa.link/0bs73j">Clique aqui.</a>
                    </Alert>
                </Box>
            }
        </>
    )
}


export default ScoutPage
