import React, { useState, useEffect } from "react"

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    TextField,
    Box,
    Divider,
    Typography,
    Tooltip
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close"

import PropTypes from "prop-types"

import { CnpjMask, TelephoneMask, PostalCodeMask } from "../../../utils/masks"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const ContactEnterpriseDialog = ({ open, handleClose, createEnterprise, updateEnterprise, selectedEnterprise }) => {

    const [state, setState] = useState({
        id            : null,
        name          : "",
        socialReason  : "",
        telephone     : "",
        email         : "",
        cnpj          : "",
        address       : "",
        addressNumber : "",
        addressExtra  : "",
        neighborhood  : "",
        city          : "",
        estate        : "",
        postalCode    : "",
    })

    const submit = () => (selectedEnterprise !== null && selectedEnterprise !== undefined) ? updateEnterprise(state) : createEnterprise(state)

    useEffect(() => {
        if (selectedEnterprise !== null && selectedEnterprise !== undefined) {
            setState((prevState) => {
                const updatedState = Object.keys(selectedEnterprise).reduce((acc, key) => {
                    const parsedKey = key.replace(/_([a-z])/g, (match, group1) => group1.toUpperCase())
                    if (prevState.hasOwnProperty(parsedKey)) 
                        acc[parsedKey] = selectedEnterprise[key] || ""
                    return acc
                }, {})

                return { ...prevState, ...updatedState }
            })
        }
    }, [selectedEnterprise])

    return (
        <Dialog
            open={open}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            disablePortal
            disableEnforceFocus
            fullWidth
            maxWidth="sm"
            sx={{ position: "absolute" }}
        >
            <DialogTitle id="alert-dialog-slide-title" className={'group-flexbox'}>
                {selectedEnterprise !== null ? "Empresa" : "Nova Empresa"}
                <Tooltip title="Fechar">
                    <Button className="action-button">
                        <CloseIcon onClick={handleClose} />
                    </Button>
                </Tooltip>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    <TextField
                        size="small"
                        label="Nome"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={state.name}
                        onChange={(event) => setState((prevState) => ({ ...prevState, name: event.target.value }))}
                        className="push-bottom"
                    />
                    <TextField
                        size="small"
                        label="Razão Social"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={state.socialReason}
                        onChange={(event) => setState((prevState) => ({ ...prevState, socialReason: event.target.value }))}
                        className="push-bottom"
                    />
                    <TextField
                        size="small"
                        label="CNPJ"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={state.cnpj}
                        onChange={(event) => setState((prevState) => ({ ...prevState, cnpj: event.target.value }))}
                        className="push-bottom"
                        InputProps={{ inputComponent: CnpjMask }}
                    />
                    <Box style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                        <TextField
                            size="small"
                            label="Telefone"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={state.telephone}
                            onChange={(event) => setState((prevState) => ({ ...prevState, telephone: event.target.value }))}
                            className="push-bottom"
                            InputProps={{ inputComponent: TelephoneMask }}
                        />
                        <TextField
                            size="small"
                            label="E-mail"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={state.email}
                            onChange={(event) => setState((prevState) => ({ ...prevState, email: event.target.value }))}
                            className="push-bottom"
                        />
                    </Box>
                    <Typography marginBottom="1rem" fontSize="19px" fontWeight="500" color="#21446c">
                        Endereço
                    </Typography>
                    <TextField
                        size="small"
                        label="Endereço"
                        fullWidth
                        type="text"
                        variant="outlined"
                        value={state.address}
                        onChange={(event) => setState((prevState) => ({ ...prevState, address: event.target.value }))}
                        className="push-bottom"
                    />
                    <Box style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                        <TextField
                            size="small"
                            label="Número"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={state.addressNumber}
                            onChange={(event) => setState((prevState) => ({ ...prevState, addressNumber: event.target.value }))}
                            className="push-bottom"
                        />
                        <TextField
                            size="small"
                            label="Complemento"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={state.addressExtra}
                            onChange={(event) => setState((prevState) => ({ ...prevState, addressExtra: event.target.value }))}
                            className="push-bottom"
                        />
                        <TextField
                            size="small"
                            label="Bairro"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={state.neighborhood}
                            onChange={(event) => setState((prevState) => ({ ...prevState, neighborhood: event.target.value }))}
                            className="push-bottom"
                        />
                    </Box>
                    <Box style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                        <TextField
                            size="small"
                            label="Cidade"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={state.city}
                            onChange={(event) => setState((prevState) => ({ ...prevState, city: event.target.value }))}
                            className="push-bottom"
                        />
                        <TextField
                            size="small"
                            label="Estado"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={state.estate}
                            onChange={(event) => setState((prevState) => ({ ...prevState, estate: event.target.value }))}
                            className="push-bottom"
                        />
                        <TextField
                            size="small"
                            label="CEP"
                            fullWidth
                            type="text"
                            variant="outlined"
                            value={state.postalCode}
                            onChange={(event) => setState((prevState) => ({ ...prevState, postalCode: event.target.value }))}
                            className="push-bottom"
                            InputProps={{ inputComponent: PostalCodeMask }}
                        />
                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={submit} color="primary" className="push-right" disabled={! state.name}>
                    Enviar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ContactEnterpriseDialog.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func
};

export default ContactEnterpriseDialog
