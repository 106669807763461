import React, { createRef } from "react";

import { 
  Button,
  Grid,
  TextField,
  InputLabel,
  OutlinedInput,
  Tooltip,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
  InputAdornment,
  FormControl,
  Autocomplete,
  ListItem,
  ListItemText
} from "@mui/material"

import { Save } from '@mui/icons-material';

import api from '../../api';
import { getSession } from "../../auth"
import Typography from "../Typography";
import OutsideClickHandler from 'react-outside-click-handler';
import "./styles.css";

import useNotification from "../Notification";
import useValidationError from '../ErrorValidation';

import { CpfMask } from "../../utils/masks"

class ChangeField extends React.Component {

  constructor(props) {

      super(props);

      this.state = {
        showChangeField : false,
        inputValue      : props.value,
        loading         : false,
        inputText       : ""
      };

      this.autocompleteRef = createRef()
  }

  fetchUpdate = async (value=null) => {
    this.setState({ loading: true });

    const dataField = (value !== null) ? value : document.getElementById(this.props.field).value;
    let body = {};

    body[this.props.field] = dataField;

    await api.patch(this.props.router, body).then(async (res) => {
      if (res.status === 200) {
        await this.setState({
          inputValue      : dataField, 
          showChangeField : false,
          loading         : false
        });
        useNotification(
          'Sucesso!', 
          'Contato alterado com sucesso.', 
          'success'
        );
        this.props.updateField(dataField);
      } else if (res.status === 205) {
        useNotification(
          'Sucesso!', 
          'Contato alterado com sucesso.', 
          'success'
        );
        this.props.reloadContact();
      } else {
        this.setState({ loading: false });
        useValidationError(res.status, null);
        throw res;
      }
    }).catch((err) => {
      console.error(err);
    });
  }

  createEnterprise(name) {
    api.post(`api/enterprises/${getSession().profile.enterprise.id}/contact_enterprises/`, {
      name: name
    }).then((res) => {
      if (res.status === 201) {
        this.props.fetchContactEnterprises()
        this.fetchUpdate(res.data["id"])
      }
    }).catch((err) => {})
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.showChangeField && ! prevState.showChangeField && this.props.field === "contact_enterprise") {
      setTimeout(() => {
        if (this.autocompleteRef.current) {
          this.autocompleteRef.current.focus()
        }
      }, 0)
    }
  }

  render() {
    let contactEnterpriseName = undefined
    if (this.props.contactEnterprises) {
      const contactEnterprise = this.props.contactEnterprises.find((enterprise) => enterprise.id == this.state.inputValue)
      contactEnterpriseName = contactEnterprise ? contactEnterprise.name : ""
    }
    return (
      <div onClick={() => this.setState({ showChangeField: true })}>
        {(!this.state.showChangeField || this.props.disabledChange) &&
          <Typography 
              text={this.state.inputValue
                ? contactEnterpriseName !== undefined
                  ? contactEnterpriseName
                  : this.state.inputValue
                : this.props.label}
              fontStyle={this.state.inputValue
                ? ""
                : "italic"}
              color={this.state.inputValue
                ? ""
                : "lightGray"}
          />
        }
        {this.state.showChangeField && !this.props.disabledChange &&
          <>
            {this.props.contactEnterprises &&
                <FormControl sx={{ width: 200 }} size="small">
                  <Autocomplete
                    options={this.props.contactEnterprises}
                    getOptionLabel={(option) => option.name || ""}
                    value={this.props.contactEnterprises.find(e => e.id === this.state.inputValue) || null}
                    onChange={(event, newValue) => {
                      this.setState({ inputValue: newValue ? newValue.id : "" });
                      this.fetchUpdate(newValue ? newValue.id : "");
                    }}
                    onInputChange={(event, newInputValue) => {
                      this.setState({ inputText: newInputValue })
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label={this.props.label} size="small" inputRef={this.autocompleteRef} />
                    )}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onBlur={() => this.setState({ showChangeField: false })}
                    noOptionsText={
                      <Button onClick={() => this.createEnterprise(this.state.inputText)} variant="contained" sx={{ width: "100%" }}>
                        Criar Empresa
                      </Button>
                    }
                  />
                </FormControl>
            }
            {! this.props.contactEnterprises &&
              <OutsideClickHandler onOutsideClick={() => this.setState({ showChangeField: false })}>
                <TextField
                  id={this.props.field}
                  label={this.props.label}
                  defaultValue={this.state.inputValue}
                  disabled={this.state.loading}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={`Salvar ${this.props.label.toLowerCase()}`} placement="bottom" disableInteractive>
                          <IconButton
                            onClick={() => this.fetchUpdate()}
                            edge="end"
                            size="small">
                              {this.state.loading ? <CircularProgress size={25} /> : <Save />}
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                    ...(this.props.cpfField && { inputComponent: CpfMask })
                  }}
                />
              </OutsideClickHandler>
            }
          </>
        }
      </div>
    );
  }
};

export default ChangeField;
